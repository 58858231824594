import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import AppFooter from '../components/CustomFooter';
import { OfficeLocations } from '../constants/officeLocations';
import { AppButton } from '../components/button';
import globeMapSvg from '../assets/globe/globe-map.svg';
import ContactImg1 from '../assets/images/contactimg3.jpg';
import Carousel from 'react-material-ui-carousel';
import MenuModal from '../components/MenuModal';
import BackgroundFallingLines from '../components/BackgroundFallingLines';
import FixedIcon from '../components/FixedIcon';

const ContactUs = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(1200);

  const [values, setValues] = React.useState({});

  const handleInputChange = ({ target }) => {
    const { id, value } = target;
    setValues({ ...values, [id]: value });
  };

  const handleSubmit = () => {
    const { name, surname, email, phoneNumber } = values;

    const calendlyUrl = `https://calendly.com/ahmad-3cubes/consultation?name=${encodeURIComponent(
      name
    ).trim()}%20${encodeURIComponent(surname).trim()}&email=${encodeURIComponent(
      email
    ).trim()}&phone=${encodeURIComponent(phoneNumber).trim()}`;
    let newWindow = window.open(calendlyUrl, '_blank');
    setTimeout(() => {
      newWindow.focus();
    }, 500);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (width < 1200) {
      setIsMobile(true);
    }
    if (width >= 1200) {
      setIsMobile(false);
    }
  });

  return (
    <div className="__format">
      <FixedIcon />
      <BackgroundFallingLines />
      <MenuModal />

      <div className="blog-container">
        <h1 className="contact-us-title">Contact us</h1>
        <div className="__flex_col contact-us-image-and-offices">
          {isMobile ? (
            <div className="__flex_row  contact-us-offices-container">
              <Carousel className="contact-us-carrousel">
                {OfficeLocations.map((office, i) => (
                  <div key={i} className="contact-us-office">
                    <h1 className="contact-us-office-title">{office.name}</h1>
                    <p>{office.address}</p>
                    <p>
                      <b>M:</b> {office.email}
                    </p>
                    <p>
                      <b>P:</b> {office.telephone}
                    </p>
                  </div>
                ))}
              </Carousel>{' '}
            </div>
          ) : null}
        </div>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="contact-us-image-container">
            <img alt={'bg-map.jpg'} src={globeMapSvg} width="100%" className="contact-us-image" />

            <div className="footer-hex-container pos1">
              <div className="footer-hex" />
            </div>
            <div className="footer-hex-container pos2">
              <div className="footer-hex" />
            </div>
            <div className="footer-hex-container pos3">
              <div className="footer-hex" />
            </div>
          </div>
        </Grid>

        {!isMobile ? (
          <div className="__flex_row  contact-us-offices-container">
            {OfficeLocations.map((office, i) => (
              <div key={i} className="footer-office">
                <h1 className="contact-us-office-title">{office.name}</h1>
                <p>{office.address}</p>
                <p>
                  <b>M:</b> {office.email}
                </p>
                <p>
                  <b>P:</b> {office.telephone}
                </p>
              </div>
            ))}
          </div>
        ) : null}

        <div className="contact-us-feedback-container">
          <img alt="img" src={ContactImg1} className="contact-us-feedback-image" />
          <div className="contact-us-feedback-text-container">
            <h2 className="contact-us-feedback-title">Global Client Success</h2>
            <p>
              Your feedback is the cornerstone of our growth. We celebrate the diverse experiences
              of our clients from around the world. Join us on this journey and let`s innovate for a
              better tomorrow together.
            </p>
            <p>
              Our commitment to excellence is echoed in the stories of clients across the globe.
              From small startups to multinational corporations, our solutions have been pivotal in
              transforming visions into realities. Your satisfaction is our achievement – be it in
              deploying cutting-edge blockchain technologies, creating dynamic web experiences, or
              pioneering AI innovations. Share your success story with us and inspire a global
              community.
            </p>
            <div className="contact-us-feedback-table">
              <div className="contact-us-feedback-item">
                <h3>Year Established</h3>
                <p>2021</p>
              </div>
              <div className="contact-us-feedback-item">
                <h3>Global Reach</h3>
                <p>Customers from 8 countries</p>
              </div>
              <div className="contact-us-feedback-item">
                <h3>Projects Completed</h3>
                <p>125</p>
              </div>

              <div className="contact-us-feedback-item">
                <h3>Revenue Yearly</h3>
                <p>$25M</p>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-us-asked-questions">
          <div className="contact-us-asked-questions-title-container">
            <h2>Frequently Asked Questions</h2>
            <p>
              Got questions? We have answers. And if you don`t find what you`re looking for, our
              customer support team is just a click away.
            </p>
            <p>Ready to bring your vision to life? Contact our team.</p>
          </div>
          <ul className="contact-us-asked-questions-table">
            <li key={1} className="contact-us-asked-questions-item">
              <h3>Who typically uses your services?</h3>
              <p>
                Our services cater to a wide range of clients including startups, established
                companies, individual entrepreneurs, and government agencies. We specialize in
                tailoring solutions to meet the diverse needs of our clients across various
                industries, from finance and healthcare to retail and technology.
              </p>
            </li>
            <li key={2} className="contact-us-asked-questions-item">
              <h3>How do you manage communication and collaboration with clients?</h3>
              <p>
                We use a range of tools like email, video conferencing, project management software,
                and instant messaging for seamless communication. Our clients are kept in the loop
                throughout the development process, ensuring a collaborative and inclusive approach.
              </p>
            </li>
            <li key={3} className="contact-us-asked-questions-item">
              <h3> Can your team handle large-scale projects?</h3>
              <p>
                Absolutely. Our team consists of experienced professionals with expertise in
                handling large-scale, complex projects. We leverage the latest technologies and
                methodologies, ensuring scalable and efficient solutions regardless of the project
                size.
              </p>
            </li>
            <li key={4} className="contact-us-asked-questions-item">
              <h3>What is your pricing model?</h3>
              <p>
                Our pricing model is project-specific, considering factors like project complexity,
                technology stack, team requirements, and timeline. We offer detailed project quotes
                after understanding your requirements to provide a transparent, competitive and fair
                pricing structure.
              </p>
            </li>
            <li key={5} className="contact-us-asked-questions-item">
              <h3>What types of blockchain solutions do you offer?</h3>
              <p>
                We specialize in custom blockchain solutions including smart contracts development,
                cryptocurrency wallet creation, blockchain network setup with validators and miners,
                and blockchain explorer tools. Our services are tailored to meet the unique needs of
                your project, whether it`s a new venture or integrating blockchain into existing
                systems.
              </p>
            </li>
            <li key={6} className="contact-us-asked-questions-item">
              <h3>Can you describe your approach to web infrastructure and architecture?</h3>
              <p>
                We focus on robust and scalable web infrastructure using modern cloud solutions like
                AWS, Azure, and Google Cloud. Our architecture approach involves microservices,
                containerization, serverless computing, and ensuring high availability and load
                balancing for large-scale applications.
              </p>
            </li>
            <li key={7} className="contact-us-asked-questions-item">
              <h3>How do you approach a new AI project?</h3>
              <p>
                We start by understanding your specific needs and challenges. Then we propose a
                bespoke AI solution that includes data collection and processing, model selection
                and training, and deployment strategies. Our team ensures the solution is scalable,
                reliable, and ethically aligned with your business values.
              </p>
            </li>
          </ul>
        </div>
        <Grid
          sx={{
            width: '100%'
          }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          marginTop="3rem"
          marginBottom="3rem">
          <div>
            <div>
              <h1 style={{ fontSize: '3rem', fontWeight: '200' }}>Let`s talk about your project</h1>
            </div>
            <Grid container spacing="1em">
              <Grid item xs={12} lg={6} sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  label="Name"
                  id="name"
                  variant="standard"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  label="Surname"
                  id="surname"
                  variant="standard"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  label="E-Mail Address"
                  id="email"
                  variant="standard"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  label="Phone Number with country code"
                  id="phoneNumber"
                  variant="standard"
                  onChange={handleInputChange}
                />
              </Grid>
              {/* <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
                <TextField
                  multiline={true}
                  fullWidth
                  label="Message"
                  id="message"
                  variant="standard"
                  onChange={handleInputChange}
                />
              </Grid> */}
            </Grid>
            <Grid container justifyContent="end">
              <Grid item xs={12} lg={4} xl={4}>
                <AppButton buttonText="Schedule" onClick={handleSubmit} />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>

      <AppFooter ignorePosition={true} />
    </div>
  );
};

export default ContactUs;
