import { Grid, TextField } from '@mui/material';
import { AppButton } from './button';

import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ContactModal = ({ onClose }) => {
  const [values, setValues] = useState({});

  const handleInputChange = ({ target }) => {
    const { id, value } = target;
    setValues({ ...values, [id]: value });
  };

  const handleSubmit = () => {
    const { first_name, last_name, email, phoneNumber } = values;

    const calendlyUrl = `https://calendly.com/ahmad-3cubes/consultation?name=${encodeURIComponent(
      first_name
    ).trim()}%20${encodeURIComponent(last_name).trim()}&email=${encodeURIComponent(
      email
    ).trim()}&phone=${encodeURIComponent(phoneNumber).trim()}`;
    let newWindow = window.open(calendlyUrl, '_blank');
    setTimeout(() => {
      newWindow.focus();
    }, 500);
  };

  return (
    <>
      <div style={{ margin: '0 1rem' }}>
        <div>
          <h1 style={{ fontWeight: '300', fontSize: '42px' }}>Contact Form</h1>
        </div>
        <Grid container spacing="1em">
          <Grid item xs={12} lg={6} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="First Name"
              id="first_name"
              variant="standard"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Last Name"
              id="last_name"
              variant="standard"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="E-Mail Address"
              id="email"
              variant="standard"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              type="number"
              label="Phone Number with country code"
              id="phoneNumber"
              variant="standard"
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Message"
              id="message"
              variant="standard"
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={12} lg={4}>
            <AppButton buttonText="Schedule" onClick={handleSubmit} />
          </Grid>
        </Grid>
      </div>
      <div style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={onClose}>
        <CloseIcon fontSize="large" className={'close-button'} />
      </div>
    </>
  );
};

export default ContactModal;
