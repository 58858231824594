import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import oneLogo from '../assets/icons/1.png';
import twoLogo from '../assets/icons/2.png';
import threeLogo from '../assets/icons/3.png';
import binanceLogo from '../assets/companies/binance-Logo.wine.png';
import coinmarketcapLogo from '../assets/companies/coinmarketcap-vector-logo.png';
import ftxLogo from '../assets/companies/ftx.png';
import microsoftLogo from '../assets/companies/microsoft-logo-black-and-white.png';
import globe from '../assets/globe/globe.mp4';
import { useNavigate } from 'react-router-dom';
import { useScroll, useTransform, motion } from 'framer-motion';

const serviceSnippets = [
  'Explore the transformative power of blockchain technology with services including smart contract development, cryptocurrency wallet creation, and customized blockchain network setups. Dive into the realm of Ethereum, Binance Smart Chain, and Hyperledger, focusing on security and efficiency for decentralized applications and private blockchain networks.',
  "Our web development services are a fusion of AWS and Google Cloud technologies, ensuring robust and versatile digital solutions. Utilizing AWS components like Lambda for serverless computing, S3 for storage, and Elastic Beanstalk for app deployment, combined with Google Cloud's BigQuery for data analytics and Kubernetes Engine for container orchestration. Front-end development leverages React, Redux for state management, and Angular, while back-end expertise includes Node.js, Express, and Python with Django. Blockchain integration with Ethereum and Hyperledger brings cutting-edge DApp development into the mix.",
  'Our AI development services specialize in integrating advanced machine learning models using frameworks like TensorFlow and PyTorch. We excel in fine-tuning models for specific applications, incorporating Natural Language Processing with GPT and BERT for enhanced text analysis. Reinforcement learning algorithms and reward systems are key in our approach, allowing AI systems to evolve through interactive learning. We also utilize RAG models for comprehensive information retrieval, ensuring AI systems deliver accurate and contextually relevant responses.'
];

const Container = ({ children, isMobile }) => {
  const viewportHeight = window.innerHeight;
  const { scrollY } = useScroll();

  const containerOpacity = useTransform(
    scrollY,
    [viewportHeight * 1.8, viewportHeight * 2, viewportHeight * 3, viewportHeight * 3.5],
    [0, 1, 1, 0]
  );
  const containerTransform = useTransform(
    scrollY,
    [viewportHeight * 1.5, viewportHeight * 2, viewportHeight * 3, viewportHeight * 3.5],
    [viewportHeight * 2, 0, 0, -viewportHeight]
  );

  if (isMobile) {
    return <section className={`sectionThree`}>{children}</section>;
  }

  return (
    <motion.section
      className={`sectionThree`}
      style={{
        opacity: containerOpacity,
        top: containerTransform
      }}>
      {children}
    </motion.section>
  );
};

const HomeThirdSection = ({ isMobile }) => {
  const navigate = useNavigate();

  const viewportHeight = window.innerHeight;
  const { scrollY } = useScroll();

  const firstIconAppears = useTransform(
    scrollY,
    [viewportHeight * 1.8, viewportHeight * 2.3],
    [0, 1]
  );
  const firstBackgroundAppears = useTransform(
    scrollY,
    [viewportHeight * 1.9, viewportHeight * 2.4],
    [0, 1]
  );
  const firstTextAppears = useTransform(
    scrollY,
    [viewportHeight * 2, viewportHeight * 2.5],
    [0, 1]
  );

  const secondIconAppears = useTransform(
    scrollY,
    [viewportHeight * 2, viewportHeight * 2.5],
    [0, 1]
  );
  const secondBackgroundAppears = useTransform(
    scrollY,
    [viewportHeight * 2.1, viewportHeight * 2.6],
    [0, 1]
  );
  const secondTextAppears = useTransform(
    scrollY,
    [viewportHeight * 2.2, viewportHeight * 2.7],
    [0, 1]
  );

  const thirdIconAppears = useTransform(
    scrollY,
    [viewportHeight * 2.2, viewportHeight * 2.7],
    [0, 1]
  );
  const thirdBackgroundAppears = useTransform(
    scrollY,
    [viewportHeight * 2.3, viewportHeight * 2.8],
    [0, 1]
  );
  const thirdTextAppears = useTransform(
    scrollY,
    [viewportHeight * 2.4, viewportHeight * 2.9],
    [0, 1]
  );

  return (
    <Container isMobile={isMobile}>
      <div className="big-globe-bg-container">
        <video className="big-globe-bg" autoPlay muted loop>
          <source src={globe} type="video/mp4" />
        </video>
      </div>
      {isMobile ? (
        <Carousel className="service-cards-container">
          {[
            { name: 'BLOCKCHAIN DEVELOPMENT', url: '/services/1', src: oneLogo },
            { name: 'WEB DEVELOPMENT', url: '/services/2', src: twoLogo },
            { name: 'AI DEVELOPMENT', url: '/services/3', src: threeLogo }
          ].map((obj, i) => (
            <div
              key={`service-${obj.name}`}
              className="service-card"
              onClick={() => navigate(obj.url)}>
              <img src={obj.src} width="150px" className={`service-card-icon icon-${i + 1}`} />
              <div className="service-card-bg">
                <div className={'service-card-text-container'}>
                  <h1 className={'service-card-name'}>{obj.name}</h1>
                  <h1 className={'service-card-description'}>{serviceSnippets[i]}</h1>
                  <div className={'service-card-button'}>
                    <a href={obj.url} style={{ textDecoration: 'none' }}>
                      <Button
                        variant="text"
                        endIcon={<ArrowForwardIcon />}
                        style={{ color: 'white' }}>
                        Read more
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      ) : (
        <div className="service-cards-container">
          {[
            { name: 'BLOCKCHAIN DEVELOPMENT', url: '/services/1', src: oneLogo },
            { name: 'WEB DEVELOPMENT', url: '/services/2', src: twoLogo },
            { name: 'AI DEVELOPMENT', url: '/services/3', src: threeLogo }
          ].map((obj, i) => (
            <div
              key={`service-${obj.name}`}
              className={`service-card`}
              onClick={() => navigate(obj.url)}>
              <motion.img
                src={obj.src}
                width="150px"
                style={{
                  opacity:
                    i === 0 ? firstIconAppears : i === 1 ? secondIconAppears : thirdIconAppears
                }}
                className={`service-card-icon icon-${i + 1} ${'service-card-icon-' + (i + 1)}`}
              />

              <motion.div
                style={{
                  scaleY:
                    i === 0
                      ? firstBackgroundAppears
                      : i === 1
                      ? secondBackgroundAppears
                      : thirdBackgroundAppears
                }}
                className={`service-card-bg ${'service-card-bg-' + (i + 1)}`}>
                <motion.div
                  style={{
                    opacity:
                      i === 0 ? firstTextAppears : i === 1 ? secondTextAppears : thirdTextAppears
                  }}
                  className={`service-card-text-container ${
                    'service-card-text-container-' + (i + 1)
                  }
                `}>
                  <h1 className={'service-card-name'}>{obj.name}</h1>
                  <h1 className={'service-card-description'}>{serviceSnippets[i]}</h1>
                  <div className={'service-card-button'}>
                    <a href={obj.url} style={{ textDecoration: 'none' }}>
                      <Button
                        variant="text"
                        endIcon={<ArrowForwardIcon />}
                        style={{ color: 'white' }}>
                        Read more
                      </Button>
                    </a>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          ))}
        </div>
      )}
      <div className="companies-container">
        {[microsoftLogo, coinmarketcapLogo, binanceLogo, ftxLogo].map((item, i) => (
          <img key={i} src={item} width="200px" className={`company-logo company-logo-${i + 1}`} />
        ))}
      </div>
    </Container>
  );
};

export default HomeThirdSection;
