import React, { useEffect, useState } from 'react';
import AppFooter from '../components/CustomFooter';
import MenuModal from '../components/MenuModal';
import BackgroundFallingLines from '../components/BackgroundFallingLines';
import logoColored from '../assets/logo/logo_colored.svg';
import Gallery from '../components/Gallery';
import { ProjectCategories } from '../constants/projectCategories';
import { useNavigate } from 'react-router-dom';
import { getFilteredProjects, getSimplifiedProjects } from '../constants/projects';

const Projects = () => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState('all');
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const allProjects = getSimplifiedProjects();

    setProjects(allProjects);
  }, []);

  useEffect(() => {
    if (!activeCategory || activeCategory.toLowerCase() === 'all') {
      const allProjects = getSimplifiedProjects();
      return setProjects(allProjects);
    }
    const filteredProjects = getFilteredProjects(activeCategory);
    return setProjects(filteredProjects);
  }, [activeCategory]);

  if (!projects) return null;

  return (
    <div className="__format">
      <img src={logoColored} alt="3Cubes Logo" className="logo-colored logo-colored-appears" />

      <BackgroundFallingLines />
      <MenuModal />

      <div className="blog-container">
        <Gallery
          title="Our Projects"
          filters={ProjectCategories.map((category) => ({
            id: category,
            name: category
          }))}
          activeFilter={activeCategory}
          setActiveFilter={setActiveCategory}
          data={projects.map((project, i) => ({
            id: i,
            title: project.title,
            imgUrl: project.url,
            onClick: () => navigate(`/projects/${project.id}`),
            titlePosition: 'top',
            badge: project.category
          }))}
        />
      </div>

      <AppFooter ignorePosition={true} />
    </div>
  );
};

export default Projects;
