import React, { useEffect } from 'react';
import { Chip, IconButton } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AppFooter from '../components/CustomFooter';
import MenuModal from '../components/MenuModal';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import BackgroundFallingLines from '../components/BackgroundFallingLines';
import parse from 'html-react-parser';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FixedIcon from '../components/FixedIcon';
import { getBlogById, getSimplifiedBlogs } from '../constants/blogs';
import RelatedContent from '../components/RelatedContent';

const BlogContent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isMobile, setIsMobile] = useState(false);

  const [relatedBlogs, setRelatedBlogs] = useState([]);

  // get width when window is resize
  useEffect(() => {
    // get window width
    const width = window.innerWidth;
    if (width < 1200) {
      setIsMobile(true);
    }
    if (width >= 1200) {
      setIsMobile(false);
    }
  });

  const [item, setItem] = useState(null);

  useEffect(() => {
    if (params.blogId == null) return navigate('/blog');

    const getItem = getBlogById(params.blogId);

    setItem(getItem);

    const relatedBlogs = getSimplifiedBlogs();

    setRelatedBlogs(relatedBlogs);

    return window.scrollTo(0, 0);
  }, [params.blogId]);

  if (item == null) return null;

  return (
    <>
      <div className="__format">
        <FixedIcon />
        <BackgroundFallingLines />
        <MenuModal />

        <div className="blog-container">
          <p className="gallery-item-description-data">
            <p className="blog-main-category">{item.category}</p>
            <DateRangeIcon />
            <p>{item.datePosted} </p>
            <p>{item.readingTime}</p>
          </p>
          <h1 className="gallery-title">{item.title}</h1>
          {item.badges != null ? (
            <div className="gallery-categories">
              {item.badges.map((badge) => (
                <Chip
                  key={`blog-badge-${badge}`}
                  label={badge}
                  name={badge}
                  sx={{ m: '5px 5px' }}
                />
              ))}
            </div>
          ) : null}
          <img className="blog-banner-img" src={item.imgUrl} alt={item.name} />
          <p className="blog-content">{parse(item.content)}</p>

          <div className="blog-content-images-container">
            {isMobile ? (
              <Carousel className="footer-offices-carrousel">
                {item.images.map(({ url, alt }) => (
                  <img key={alt} src={url} alt={alt} className="blog-content-image" />
                ))}
              </Carousel>
            ) : (
              item.images.map(({ url, alt }) => (
                <img key={alt} src={url} alt={alt} className="blog-content-image" />
              ))
            )}
          </div>
          <RelatedContent
            title="related blog post"
            data={relatedBlogs.map((blog) => ({
              title: blog.title,
              imgUrl: blog.imgUrl,
              badge: blog.category,
              description: (
                <p className="gallery-item-description-data">
                  <DateRangeIcon />
                  <p>{blog.datePosted} </p>
                  <p>{blog.readingTime}</p>
                </p>
              ),
              onClick: () => navigate(`/blog/${blog.id}`)
            }))}
          />
        </div>

        <div className="blog-social">
          <a href="https://www.facebook.com/">
            <IconButton>
              <FacebookRoundedIcon className={'blog-social-icon'} />
            </IconButton>
          </a>
          <a href="https://www.instagram.com/">
            <IconButton>
              <InstagramIcon className={'blog-social-icon'} />
            </IconButton>
          </a>
          <a href="https://www.twitter.com/">
            <IconButton>
              <TwitterIcon className={'blog-social-icon'} />
            </IconButton>
          </a>
        </div>

        <AppFooter ignorePosition={true} />
      </div>
    </>
  );
};

export default BlogContent;
