import React, { useEffect, useState } from 'react';
import AppFooter from '../components/CustomFooter';
import MenuModal from '../components/MenuModal';
import BackgroundFallingLines from '../components/BackgroundFallingLines';
import Gallery from '../components/Gallery';
import FixedIcon from '../components/FixedIcon';
import { getSimplifiedServices } from '../constants/services';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);

  useEffect(() => {
    const allServices = getSimplifiedServices();
    return setServices(allServices);
  }, []);

  if (!services) return null;

  return (
    <div className="__format">
      <FixedIcon />
      <BackgroundFallingLines />
      <MenuModal />

      <div className="blog-container">
        <Gallery
          title="Our Services"
          data={services.map((service, i) => ({
            id: i,
            title: service.title,
            imgUrl: service.imgUrl,
            onClick: () => navigate(`/services/${service.id}`),
            titlePosition: 'top'
          }))}
        />
      </div>

      <AppFooter ignorePosition={true} />
    </div>
  );
};

export default Services;
