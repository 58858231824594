import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import drawerIcon from '../assets/icons/drawer.svg';
import CustomMenu from './CustomMenu';
import FixedIcon from './FixedIcon';

const MenuModal = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="navigation-container">
      <div className={`navigation-button `}>
        <div onClick={() => setOpenModal(true)}>
          <img src={drawerIcon} />
        </div>
      </div>
      <Drawer
        anchor={'top'}
        open={openModal}
        onClose={() => setOpenModal(false)}
        style={{
          height: '100vh'
        }}>
        <Box
          sx={{ width: 'auto' }}
          role="presentation"
          style={{
            backgroundColor: '#030e62',
            paddingBottom: '3rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}>
          <div style={{ width: '100%' }}>
            <FixedIcon />
            <div>
              <CustomMenu modalContent={'navigation'} onClose={() => setOpenModal(false)} />
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default MenuModal;
