import React from 'react';
import aboutUsImage from '../assets/images/3cubes-team2.png';
import { motion, useScroll, useTransform } from 'framer-motion';

const HomeFifthSection = () => {
  const viewportHeight = window.innerHeight;
  const { scrollY } = useScroll();

  const containerTransform = useTransform(
    scrollY,
    [viewportHeight * 5, viewportHeight * 5.5, viewportHeight * 6, viewportHeight * 6.5],
    [viewportHeight * 2, 0, 0, -viewportHeight]
  );
  const containerOpacity = useTransform(
    scrollY,
    [viewportHeight * 5.2, viewportHeight * 5.6, viewportHeight * 6.2, viewportHeight * 6.5],
    [0, 1, 1, 0]
  );

  return (
    <motion.section
      style={{
        top: containerTransform,
        opacity: containerOpacity
      }}
      id="section-five"
      className={`sectionFive`}>
      <div className="section-five-container __flex_col">
        <h2 className="section-five-title" id="section-five-title">
          <span style={{ fontWeight: '400' }}>WE BELIEVE</span> IN TEAM ENERGY <br></br>AND
          <span style={{ fontWeight: '400' }}>{' EXPERIENCE'}</span> POWER.
        </h2>
        <div className="section-five-sub-container __flex_row">
          <div className="section-five-img-container">
            <img alt="img" src={aboutUsImage} id="section-five-image" />
          </div>
          <p className="section-five-bottom-text">
            We`re a dynamic fusion of innovation and experience, hailing from Vancouver and now
            spanning across continents. Our team`s collective expertise encompasses over 10 years in
            cutting-edge engineering, over 8 years in agile development, and a decade of excellence
            in project management. This rich tapestry of skills has catapulted us to the vanguard of
            blockchain and AI technologies.
          </p>
          <p className="section-five-big-text">
            <h3 className="big-text-title">ABOUT US</h3>
            <div className="p-text text-1">
              <b style={{ fontWeight: '400' }}>
                From our inception, we have been driven by a passion for innovation and a commitment
                to excellence. Our journey began with a small, yet diverse group of tech
                enthusiasts, and today, we are proud to be a global collective of professionals from
                various fields. Our multidisciplinary approach involves integrating the latest
                technologies with traditional wisdom to solve complex challenges.
              </b>
            </div>
            <div className="p-text text-2">
              Each project we undertake is an opportunity to showcase our expertise. With over 4
              years in blockchain technology development, we`ve pioneered numerous industry firsts.
              In AI, the team`s 5 years of dedicated research and application have led to
              groundbreaking advancements. Our web development team, boasting 10 years of
              experience, combines creativity with technology to build scalable, secure, and
              engaging digital experiences.
            </div>
            <div className="p-text text-3">
              Our ethos is rooted in continuous learning and adaptation. We believe in not just
              keeping pace with technological evolution, but being active contributors to it. This
              philosophy is reflected in every project we deliver tailor-made solutions crafted
              through collaborative innovation, strategic planning, and a relentless pursuit of
              excellence.
            </div>
          </p>
        </div>
      </div>
    </motion.section>
  );
};

export default HomeFifthSection;
