import React from 'react';
import MenuModal from '../components/MenuModal';

const LegalPage = () => {
  return (
    <>
      <MenuModal />
      <div>Legal</div>
    </>
  );
};

export default LegalPage;
