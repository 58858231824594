/* eslint-disable no-unused-vars */
import aboutUsImage from '../assets/images/3cubes-team2.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useState } from 'react';
import logoWhite from '../assets/logo/logo_white.svg';
import { projectsCarouselLeft } from '../constants/appConstants';
import { Box, Button, Drawer, Grid, IconButton, Link } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import globeMapSvg from '../assets/globe/globe-map.svg';
import { OfficeLocations } from '../constants/officeLocations';
import ContactModal from './ContactModal';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { FooterLinks, FooterLinksSecondary } from '../constants/footerLink';
import { motion } from 'framer-motion';

const HomeLastSectionsMobile = ({ isMobile, handleScrollTop }) => {
  const [openModal, setOpenModal] = useState(false);

  const renderFooterBar = (isMobile) => {
    return (
      <div className="footer-appbar">
        <h3 className="footer-appbar-rights">© 2023 3CUBES, INC. ALL RIGHTS RESERVED.</h3>

        <div className="footer-appbar-links">
          <div className="footer-appbar-first-links">
            {FooterLinks.map((item, i) => (
              <>
                <a key={i} href={item.url}>
                  {item.name}
                </a>
              </>
            ))}
          </div>

          <div className={'divider'} />

          <div className="footer-appbar-second-links">
            {FooterLinksSecondary.map((item, i) => (
              <>
                <a key={i} href={item.url}>
                  {item.name}
                </a>
              </>
            ))}
            <div />
          </div>
        </div>
        <div className="footer-appbar-social">
          <a href="https://www.facebook.com/">
            <IconButton>
              <FacebookRoundedIcon className={'footer-social-icon'} />
            </IconButton>
          </a>
          <a href="https://www.instagram.com/">
            <IconButton>
              <InstagramIcon className={'footer-social-icon'} />
            </IconButton>
          </a>
          <a href="https://www.twitter.com/">
            <IconButton>
              <TwitterIcon className={'footer-social-icon'} />
            </IconButton>
          </a>
        </div>
      </div>
    );
  };

  return (
    <motion.section id="section-five" className={`sectionFive`}>
      <img src={logoWhite} className={'logo-white-mobile'} />
      <div className="section-five-container __flex_col">
        <h2 className="section-five-title" id="section-five-title">
          <span style={{ fontWeight: '400' }}>WE BELIEVE</span> IN TEAM ENERGY <br></br>AND
          <span style={{ fontWeight: '400' }}>{' EXPERIENCE'}</span> POWER.
        </h2>
        <div className="section-five-sub-container __flex_row">
          <div className="section-five-img-container">
            <img alt="img" src={aboutUsImage} id="section-five-image" />
          </div>
          <p className="section-five-bottom-text">
            We`re a dynamic fusion of innovation and experience, hailing from Vancouver and now
            spanning across continents. Our team`s collective expertise encompasses over 10 years in
            cutting-edge engineering, over 8 years in agile development, and a decade of excellence
            in project management. This rich tapestry of skills has catapulted us to the vanguard of
            blockchain and AI technologies.
          </p>
          <p className="section-five-big-text">
            <h3 className="big-text-title">ABOUT US</h3>
            <div className="p-text text-1">
              <b style={{ fontWeight: '400' }}>
                From our inception, we have been driven by a passion for innovation and a commitment
                to excellence. Our journey began with a small, yet diverse group of tech
                enthusiasts, and today, we are proud to be a global collective of professionals from
                various fields. Our multidisciplinary approach involves integrating the latest
                technologies with traditional wisdom to solve complex challenges.
              </b>
            </div>
            <div className="p-text text-2">
              Each project we undertake is an opportunity to showcase our expertise. With over 4
              years in blockchain technology development, we`ve pioneered numerous industry firsts.
              In AI, the team`s 5 years of dedicated research and application have led to
              groundbreaking advancements. Our web development team, boasting 10 years of
              experience, combines creativity with technology to build scalable, secure, and
              engaging digital experiences.
            </div>
            <div className="p-text text-3">
              Our ethos is rooted in continuous learning and adaptation. We believe in not just
              keeping pace with technological evolution, but being active contributors to it. This
              philosophy is reflected in every project we deliver tailor-made solutions crafted
              through collaborative innovation, strategic planning, and a relentless pursuit of
              excellence.
            </div>
          </p>
        </div>
      </div>

      {/* */}
      <div className="section-six-container">
        <div className="section-six-texts-container">
          <h2 className="section-six-title">
            DISCOVER OUR <br></br>
            <span>PROJECTS</span>
          </h2>

          <p className={'section-six-description'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus dapibus nibh orci,
            quis pulvinar arcu interdum a. Etiam lobortis, odio ac rutrum iaculis, enim nulla
            sollicitudin enim, venenatis scelerisque diam diam non arcu.
          </p>

          <a href="/projects" className="section-six-button">
            Discover All Projects <ArrowForwardIcon />
          </a>
        </div>
        <div className="section-six-carrousels-container">
          <div className="section-six-third-carrousel">
            {projectsCarouselLeft.map((project, i) => (
              <div key={`carrousel-${i}`} className={'third-carrousel-item imgScroll'}>
                <img src={project.img} className={'section-six-projectImg'} />
                <h1
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'white',
                    position: 'relative',
                    top: '-1rem'
                  }}>
                  {project.projectName}
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* */}
      <div className="__flex_col footer-container">
        <div className="footer-subcontainer-1">
          <div className="footer-title-container">
            <h1 className={'footer-title'}>
              LET’S GET IN <br />
              <b>TOUCH</b>
            </h1>
            <div>
              <button
                className="footer-button"
                onClick={() => {
                  setOpenModal(true);
                }}>
                CONTACT US
              </button>
            </div>
          </div>
          <div className="__flex_col footer-image-and-offices">
            <div className="__flex_row  footer-offices-container">
              {isMobile ? (
                <Carousel className="footer-offices-carrousel">
                  {OfficeLocations.map((office, i) => (
                    <div key={i} className="footer-office">
                      <h1 className="footer-office-title">{office.name}</h1>
                      <p>{office.address}</p>
                      <p>
                        <b>Contact:</b> {office.contact}
                      </p>
                    </div>
                  ))}
                </Carousel>
              ) : (
                OfficeLocations.map((office, i) => (
                  <div key={i} className="footer-office">
                    <h1 className="footer-office-title">{office.name}</h1>
                    <p>{office.address}</p>
                    <p>
                      <b>Contact:</b> {office.contact}
                    </p>
                  </div>
                ))
              )}
            </div>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="footer-image-container">
                <img alt={'bg-map.jpg'} src={globeMapSvg} width="100%" className="footer-image" />

                <div className="footer-hex-container pos1">
                  <div className="footer-hex" />
                </div>
                <div className="footer-hex-container pos2">
                  <div className="footer-hex" />
                </div>
                <div className="footer-hex-container pos3">
                  <div className="footer-hex" />
                </div>
              </div>
            </Grid>
            <div className={'lets-meet-container'}>
              <h3 className="lets-meet-title">CAREER IN 3CUBES</h3>
              <p className="lets-meet-text">Do you want to collaborate?</p>
              <div className="lets-meet-line-decoration" />
              <button
                onClick={() => {
                  setOpenModal(true);
                }}
                className="lets-meet-button">
                LET’S MEET
              </button>
            </div>
            <div className="scroll-top-button" style={isMobile ? { display: 'none' } : {}}>
              <Button
                variant="text"
                className={'footer-scroll'}
                endIcon={<ArrowForwardIcon />}
                onClick={handleScrollTop}>
                <h1>Scroll Top</h1>
              </Button>
            </div>
          </div>
        </div>
        {renderFooterBar(isMobile)}
      </div>

      <div className={'footer-scroll'}>
        <Drawer
          anchor={'top'}
          open={openModal}
          onClose={() => setOpenModal(false)}
          style={{
            height: '100vh'
          }}>
          <Box
            sx={{ width: 'auto' }}
            role="presentation"
            style={{
              backgroundColor: '#030e62',
              paddingBottom: '3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh'
            }}>
            <div style={{ width: '100%' }}>
              <div>
                <ContactModal onClose={() => setOpenModal(false)} />
              </div>
            </div>
          </Box>
        </Drawer>
      </div>
    </motion.section>
  );
};

export default HomeLastSectionsMobile;
