import POS from '../assets/images/POS.jpeg';
import Website from '../assets/images/CompanyWebsite.jpeg';
import Storage from '../assets/images/storage.jpg';
import Marketing from '../assets/images/Marketplace.jpeg';
import Sites from '../assets/images/sites.jpg';
import Vector from '../assets/images/Vector.webp';
import Management from '../assets/images/Management.webp';
import Crypto from '../assets/images/Crypto.jpg';
import Analytics from '../assets/images/Analytics.webp';
import Trade from '../assets/images/Trade.jpeg';

export const movingLinesGlobalText =
  'SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION SERVICE DIGITAL CUBE BLOCKCHAIN MARKETING 3CUBES END-TO-END SOLUTION';

export const projectsCarouselLeft = [
  {
    projectName: 'POS System',
    img: POS
  },
  {
    projectName: 'Company Websites',
    img: Website
  },
  {
    projectName: 'Secured Data Storage',
    img: Storage
  },
  {
    projectName: 'Online Marketplace',
    img: Marketing
  },
  {
    projectName: 'Booking Sites',
    img: Sites
  },
  {
    projectName: 'POS System',
    img: POS
  },
  {
    projectName: 'Company Websites',
    img: Website
  },
  {
    projectName: 'Secured Data Storage',
    img: Storage
  },
  {
    projectName: 'Online Marketplace',
    img: Marketing
  },
  {
    projectName: 'Booking Sites',
    img: Sites
  },
  {
    projectName: 'Booking Sites',
    img: Sites
  },
  {
    projectName: 'POS System',
    img: POS
  },
  {
    projectName: 'Company Websites',
    img: Website
  },
  {
    projectName: 'Secured Data Storage',
    img: Storage
  },
  {
    projectName: 'Online Marketplace',
    img: Marketing
  },
  {
    projectName: 'Booking Sites',
    img: Sites
  }
];

export const projectsCarouselRight = [
  {
    projectName: 'The Vector illustration',
    img: Vector
  },
  {
    projectName: 'Asset Management',
    img: Management
  },
  {
    projectName: 'Crypto marketplace',
    img: Crypto
  },
  {
    projectName: 'Crypto Data Analytics',
    img: Analytics
  },
  {
    projectName: 'On Phone Trade',
    img: Trade
  },
  {
    projectName: 'The Vector illustration',
    img: Vector
  },
  {
    projectName: 'Asset Management',
    img: Management
  },
  {
    projectName: 'Crypto marketplace',
    img: Crypto
  },
  {
    projectName: 'Crypto Data Analytics',
    img: Analytics
  },
  {
    projectName: 'On Phone Trade',
    img: Trade
  },
  {
    projectName: 'The Vector illustration',
    img: Vector
  },
  {
    projectName: 'Asset Management',
    img: Management
  },
  {
    projectName: 'Crypto marketplace',
    img: Crypto
  },
  {
    projectName: 'Crypto Data Analytics',
    img: Analytics
  },
  {
    projectName: 'On Phone Trade',
    img: Trade
  }
];
