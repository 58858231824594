import React, { useRef } from 'react';
import logoMp4 from '../assets/3cubes first section logo.mp4';
import cubesHeading from '../assets/logo/cube_text.svg';
import { useScroll, motion, useTransform } from 'framer-motion';

const LogoBanner = ({ isMobile }) => {
  const viewportHeight = window.innerHeight;
  const containerRef = useRef(null);
  const { scrollY } = useScroll();

  const headingOpacity = useTransform(scrollY, [0, viewportHeight], [1, 0]);

  const weAreHeadingTransform = useTransform(scrollY, [0, viewportHeight], [-45, 0]);
  const cubesHeadingTransform = useTransform(scrollY, [0, viewportHeight], [60, 0]);

  const weAreHeadingTransformMobile = useTransform(scrollY, [0, viewportHeight], [-45, 100]);
  const cubesHeadingTransformMobile = useTransform(scrollY, [0, viewportHeight], [45, -130]);

  const logoVideoOpacity = useTransform(
    scrollY,
    [0, viewportHeight, viewportHeight * 1.3],
    [1, 1, 0]
  );
  const logoVideoScaleTransform = useTransform(scrollY, [0, viewportHeight], [1, 0.6]);
  const logoVideoTransform = useTransform(scrollY, [0, viewportHeight], [0, -40]);

  const containerTransform = useTransform(scrollY, [0, viewportHeight], [0, viewportHeight]);

  const videoRef = useRef(null);

  return (
    <motion.section
      ref={containerRef}
      className={`sectionOne`}
      style={{
        ...(isMobile ? { transform: `translate(0, ${containerTransform}%)` } : {})
      }}>
      <div className="logoVideo-container">
        <motion.div
          style={{
            opacity: headingOpacity,
            ...(isMobile ? { y: weAreHeadingTransformMobile } : { x: weAreHeadingTransform }),

            zIndex: 10
          }}>
          <h1 className={'logo-heading weare-heading-start'}>we are</h1>
        </motion.div>
        <div className={'logoVideo-start logoVideo-animate-start'}>
          <motion.div
            style={{
              opacity: logoVideoOpacity,
              y: logoVideoTransform,
              width: '100%',
              height: '100%',
              scale: logoVideoScaleTransform
            }}>
            <video
              onContextMenu={(e) => e.preventDefault()}
              autoPlay
              muted
              height="100%"
              ref={videoRef}>
              <source src={logoMp4} type="video/mp4" />
            </video>
          </motion.div>
        </div>

        <motion.div
          style={{
            opacity: headingOpacity,
            ...(isMobile ? { y: cubesHeadingTransformMobile } : { x: cubesHeadingTransform })
          }}>
          <img src={cubesHeading} className={'logo-heading cubes-heading-start'} />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default LogoBanner;
