import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import App from './routes/App';
import rootReducer from './reducers';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Blog from './routes/Blog';
import BlogContent from './routes/BlogContent';
import Services from './routes/Services';
import Projects from './routes/Projects';
import ServicesContent from './routes/ServicesContent';
import ContactUs from './routes/ContactUs';
import CustomMenu from './components/CustomMenu';
import PrivacyPolicy from './routes/PrivacyPolicy';
import LegalPage from './routes/LegalPage';
import ProjectContent from './routes/ProjectContent';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    path: '/menu',
    element: <CustomMenu />
  },
  {
    path: '/blog',
    element: <Blog />
  },
  {
    path: '/blog/:blogId',
    element: <BlogContent />
  },
  {
    path: '/services',
    element: <Services />
  },
  {
    path: '/projects',
    element: <Projects />
  },
  {
    path: '/services/:serviceId',
    element: <ServicesContent />
  },
  {
    path: '/projects/:projectId',
    element: <ProjectContent />
  },
  {
    path: '/privacy',
    element: <PrivacyPolicy />
  },
  {
    path: '/legal',
    element: <LegalPage />
  },
  {
    path: '*',
    element: <App />
  }
]);

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: '#030e62'
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
