import React from 'react';
import { Grid } from '@mui/material';

const BackgroundFallingLines = () => {
  return (
    <section id="backgroundLines">
      <Grid
        container
        style={{
          position: 'fixed',
          height: '100vh',
          overflow: 'hidden',
          opacity: '0.2'
        }}>
        <span className="right speed-1 delay-1" />
        <span className="right speed-2 delay-2" />
        <span className="right speed-3 delay-3" />
        <span className="right speed-4 delay-4" />
        <span className="right speed-5 delay-5" />

        <span className="right speed-5 delay-5" />
        <span className="right speed-4 delay-4" />
        <span className="right speed-3 delay-3" />
        <span className="right speed-2 delay-2" />
        <span className="right speed-1 delay-1" />

        <span className="right speed-2 delay-2" />
        <span className="right speed-3 delay-3" />
        <span className="right speed-4 delay-5" />
        <span className="right speed-5 delay-5" />
        <span className="right speed-1 delay-1" />
      </Grid>
    </section>
  );
};

export default BackgroundFallingLines;
