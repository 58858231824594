export const OfficeLocations = [
  {
    name: 'OFFICE ONE',
    address: "Vancouver's Tech center, BC, Canada",
    contact: 'Connect with us via our contact form for Global project inquiries and partnerships.'
  },
  {
    name: 'OFFICE TWO',
    address: "Mexico City's business district, Mexico",
    contact:
      'Get in touch through our contact form for services and collaborations in South America.'
  },
  {
    name: 'OFFICE THREE',
    address: "Istanbul's tech hub, Turkey",
    contact: 'Reach us via our contact form for projects and queries in Europe.'
  }
];
