import Lottie from 'lottie-react';
import React from 'react';
import lineOpen from '../assets/lotties/Line Open.json';
import worldLottie from '../assets/lotties/World.json';
import { useScroll, useTransform, motion } from 'framer-motion';

const HomeSecondSection = () => {
  const viewportHeight = window.innerHeight;

  const { scrollY } = useScroll();

  const containerOpacity = useTransform(
    scrollY,
    [viewportHeight * 0.8, viewportHeight * 1.2, viewportHeight * 1.5, viewportHeight * 2],
    [0, 1, 1, 0]
  );

  const containerTransform = useTransform(
    scrollY,
    [0, viewportHeight, viewportHeight * 1.5, viewportHeight * 2.5],
    [viewportHeight, 0, 0, -viewportHeight]
  );

  const globeAppearsTransform = useTransform(
    scrollY,
    [viewportHeight, viewportHeight * 1.5],
    [0, 1]
  );

  return (
    <motion.section
      className={`sectionTwo`}
      style={{
        top: containerTransform,
        opacity: containerOpacity
      }}>
      <div className="globe-container">
        <motion.div
          className={`globe-animation`}
          style={{
            opacity: globeAppearsTransform
          }}>
          <Lottie animationData={worldLottie} loop={true} />
        </motion.div>

        <h1 className={'globe-title'}>
          We are a <b>global </b>
          <br></br>
          Technology Company
        </h1>
        <div className={'line-to-left'}>
          <Lottie animationData={lineOpen} loop={true} />
        </div>
        <div className={'line-to-right'}>
          <Lottie animationData={lineOpen} loop={true} />
        </div>
      </div>
    </motion.section>
  );
};

export default HomeSecondSection;
