import React, { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AppFooter from '../components/CustomFooter';
import { useNavigate, useParams } from 'react-router-dom';
import MenuModal from '../components/MenuModal';
import BackgroundFallingLines from '../components/BackgroundFallingLines';
import FixedIcon from '../components/FixedIcon';
import parse from 'html-react-parser';
import Carousel from 'react-material-ui-carousel';
import { getOtherProjects, getProjectById } from '../constants/projects';
import RelatedContent from '../components/RelatedContent';

const ProjectContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [item, setItem] = useState(null);

  const [isMobile, setIsMobile] = useState(false);

  // get width when window is resize
  useEffect(() => {
    // get window width
    const width = window.innerWidth;
    if (width < 1200) {
      setIsMobile(true);
    }
    if (width >= 1200) {
      setIsMobile(false);
    }
  });

  const [relatedProjects, setRelatedProjects] = useState([]);

  // get width when window is resize
  useEffect(() => {
    // get window width
    const width = window.innerWidth;
    if (width < 1200) {
      setIsMobile(true);
    }
    if (width >= 1200) {
      setIsMobile(false);
    }
  });

  useEffect(() => {
    if (params.projectId == null) return navigate('/projects');

    const getItem = getProjectById(params.projectId);

    setItem(getItem);

    const relatedProjects = getOtherProjects(params.projectId);

    setRelatedProjects(relatedProjects);

    return window.scrollTo(0, 0);
  }, [params.projectId]);

  if (item == null) return null;

  return (
    <>
      <div className="__format">
        <FixedIcon />
        <BackgroundFallingLines />
        <MenuModal />

        <div className="blog-container">
          <h1 className="service-title">
            {item.title}
            <div className="service-title-added">{item.category}</div>
          </h1>

          <div className="project-image-container">
            <img alt={item.name} src={item.imgUrl} className="service-image" />
          </div>

          <p className="blog-content project-content">{parse(item.content ?? '')}</p>

          {item.properties ? (
            <div className="service-properties">
              {isMobile ? (
                <Carousel className="footer-offices-carrousel">
                  {item.properties.map((property, i) => {
                    return (
                      <div className="service-property" key={`service-prop-${i}`}>
                        <img
                          alt={property.name}
                          src={property.image}
                          className="service-property-image"
                        />
                        <p className="service-property-name">{property.name}</p>
                      </div>
                    );
                  })}
                </Carousel>
              ) : (
                item.properties.map((property, i) => (
                  <div className="service-property" key={`service-prop-${i}`}>
                    <img
                      alt={property.name}
                      src={property.image}
                      className="service-property-image"
                    />
                    <p className="service-property-name">{property.name}</p>
                  </div>
                ))
              )}
            </div>
          ) : null}

          <RelatedContent
            title="other projects"
            data={relatedProjects.map((project) => ({
              title: (
                <div className="related-projects-title">
                  <p>{project.title}</p>
                  <span className="related-projects-discover-text">
                    Discover
                    <ArrowForwardIcon fontSize="small" className="related-projects-discover-icon" />
                  </span>
                </div>
              ),
              imgUrl: project.url,
              onClick: () => navigate(`/projects/${project.id}`)
            }))}
            extendedImage={true}
          />
        </div>

        <AppFooter ignorePosition={true} />
      </div>
    </>
  );
};

export default ProjectContent;
