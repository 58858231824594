import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { useNavigate } from 'react-router-dom';

const CustomMenu = ({ onClose }) => {
  const url = window.location;

  const navigate = useNavigate();

  const navMenus = [
    { id: 'home', name: 'Home', url: '/' },
    { id: 'blog', name: 'Blog', url: '/blog' },
    { id: 'projects', name: 'Projects', url: '/projects', topText: 'our' },
    { id: 'services', name: 'Services', url: '/services', topText: 'our' }
  ];

  return (
    <>
      <div className={'menu-container'}>
        {navMenus.map((menu, i) => (
          <div
            key={i}
            className={`item-container ${i % 2 === 0 ? 'item-top' : 'item-bottom'} ${
              url.pathname === menu.url ? 'item-container-active' : ''
            }`}>
            {menu.topText ? <h1 className={'item-top-text'}>{menu.topText}</h1> : null}
            <h1 id={menu.id} className={`item`} onClick={() => navigate(menu.url)}>
              {menu.name}
            </h1>
            {url.pathname === menu.url ? <div className={'item-bottom-decoration'} /> : null}
          </div>
        ))}
        <h1 id="contact" className={'item2'} onClick={() => navigate('/contact-us')}>
          Lets get in<br></br> touch
        </h1>
        <div style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={onClose}>
          <CloseIcon fontSize="large" className={'close-button'} />
        </div>
      </div>
    </>
  );
};

export default CustomMenu;
