// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-box {
    background-color: #ffffff;
    color:  #030e62;
}

.footer-scroll{
    display:flex;
    transform: rotate(-90deg);
    position: relative;
    left: 5rem;
    color: #17216f !important;
    width:10rem !important;
}

.footer-scroll h1{
    font-size: 13px;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".footer-box {\n    background-color: #ffffff;\n    color:  #030e62;\n}\n\n.footer-scroll{\n    display:flex;\n    transform: rotate(-90deg);\n    position: relative;\n    left: 5rem;\n    color: #17216f !important;\n    width:10rem !important;\n}\n\n.footer-scroll h1{\n    font-size: 13px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
