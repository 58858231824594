import Scblockchain1 from '../assets/images/ScBlockchain1.webp';
import Scblockchain2 from '../assets/images/ScBlockchain2.webp';
import Scblockchain3 from '../assets/images/ScBlockchain3.jpeg';
import ScWeb1 from '../assets/images/ScWeb1.webp';
import ScWeb2 from '../assets/images/ScWeb2.webp';
import ScWeb3 from '../assets/images/ScWeb3.webp';
import ScMarket1 from '../assets/images/ScMarket1.webp';
import ScMarket2 from '../assets/images/ScMarket2.webp';
import ScMarket3 from '../assets/images/ScMarket3.jpeg';

import blockchainBg from '../assets/services/Design.png';
import marketingBg from '../assets/services/Marketing.png';
import webBg from '../assets/services/Technology.png';

import servicePropImg from '../assets/services/service-prop.png';

export const services = [
  {
    id: 1,
    title: 'Blockchain Services: Pioneering Your Digital Frontier',
    description: 'description',
    imgUrl: blockchainBg,
    properties: [
      {
        name: 'Architecture',
        image: servicePropImg
      },
      {
        name: 'Development',
        image: servicePropImg
      },
      {
        name: 'Testing',
        image: servicePropImg
      },
      {
        name: 'Deployment',
        image: servicePropImg
      }
    ],
    content: `<p>Embark on a transformative journey in the digital realm with cutting-edge blockchain services. 
    These services are at the forefront of innovation, harnessing the power of blockchain technology to redefine business operations globally. 
    Specializing in advanced blockchain solutions, they are designed to cater to a myriad of unique requirements.</p>
    <h2>Advanced Blockchain Expertise</h2>
        <ul>
          <li><strong>Diverse Networks and Cutting-Edge Technologies:</strong> Mastery over blockchain networks like 
          Ethereum, Binance Smart Chain, Solana, Cardano, and Hyperledger. Focus lies in using these platforms for 
          crafting decentralized applications (DApps), smart contracts, and secure private networks, enhancing security 
          and operational efficiency. We also can deploy rollups L2/L3 layers for EVM blockchains.</li>
          <li><strong>Smart Contract Development with OpenZeppelin Standards:</strong> Developing a wide array of smart contracts for DeFi applications, NFT marketplaces, and supply chain management. Emphasis on robust auditing processes to ensure top-tier security and compliance, adhering to OpenZeppelin standards for best practices in smart contract security.</li>
          <li><strong>Innovative Cryptocurrency Wallets and EIP-4337 Standards:</strong> Creating advanced, user-friendly cryptocurrency wallets, supporting a variety of currencies. These wallets incorporate the latest EIP-4337 standards for abstracted wallet layers, ensuring seamless transactions and effective asset management.</li>
          <li><strong>Strategic Blockchain Network Infrastructure:</strong> Delivering comprehensive services to establish blockchain networks, complete with validators and miners, crafting a resilient and efficient ecosystem.</li>
          <li><strong>Seamless Blockchain Integration in Various Sectors:</strong> Integrating blockchain technology into existing systems, enhancing transparency and security across operations. This includes seamless compatibility with databases, ERP systems, and IoT devices, showcasing versatility across sectors.</li>
          <li><strong>Advanced Protocols and Consensus Mechanisms:</strong> Utilizing sophisticated consensus mechanisms like Proof of Work (PoW), Proof of Stake (PoS), and Delegated Proof of Stake (DPoS) to guarantee network integrity and security.</li>
          <li><strong>Revolutionary Decentralized Finance (DeFi) and DAO Solutions:</strong> Developing innovative DeFi platforms for lending, borrowing, staking, and yield farming. Incorporating governance models and DAOs (Decentralized Autonomous Organizations) for democratized financial ecosystems.</li>
          <li><strong>Next-Gen NFT Solutions with Merkle Roots and Signatures:</strong> Designing unique NFT platforms incorporating advanced concepts like Merkle roots and digital signatures, offering secure and verifiable ownership and transfer of digital assets.</li>
        </ul>
        <h2>Comprehensive Process for Optimal Solutions</h2>
        <ul>
        <li><strong>Tailored Requirement Analysis:</strong> A deep dive into specific needs and project scope.</li>
        <li><strong>Innovative Solution Design:</strong> Utilizing the latest in blockchain technology to craft bespoke solutions.</li>
        <li><strong>Efficient Development and Integration:</strong> Implementing and harmoniously integrating solutions into existing systems.</li>
        <li><strong>Thorough Testing and Deployment:</strong> Extensive testing to ensure robust deployment for real-world application.</li>
        <li><strong>Dedicated Support and Maintenance:</strong> Continuous support and maintenance for seamless operation.</li>
      </ul>
      <h2>Transforming Industries with Blockchain</h2>
      <p>This approach to blockchain services is not just about technology; 
      it's about reimagining business processes and creating opportunities for innovation. 
      From startups to global corporations, these services are designed to turn visions into tangible realities, 
      driving growth and success in an ever-evolving digital landscape. Explore the possibilities and 
      step into a future shaped by blockchain excellence.</p>
        `,
    images: [
      {
        url: Scblockchain1
      },
      {
        url: Scblockchain2
      },
      {
        url: Scblockchain3
      }
    ],
    nextItem: {
      title: 'WEB DEVELOPMENT',
      id: 2
    },
    prevItem: {
      title: 'AI DEVELOPMENT',
      id: 3
    }
  },
  {
    id: 2,
    title: 'Web Development Services: Architecting the Future of Digital Solutions',
    description: 'description',
    imgUrl: webBg,
    properties: [
      {
        name: 'Architecture',
        image: servicePropImg
      },
      {
        name: 'Development',
        image: servicePropImg
      },
      {
        name: 'Testing',
        image: servicePropImg
      },
      {
        name: 'Deployments',
        image: servicePropImg
      }
    ],
    content: `<p>
    Embark on a journey of digital transformation with our state-of-the-art web development services. At the cutting edge of technological advancements, we specialize in creating complex, dynamic web applications that integrate the latest in AWS architecture and blockchain innovations, catering to a broad spectrum of unique business needs.
    </p>
    <h2>Advanced Web and Cloud Technologies</h2>
    <li><strong>AWS and Google Powered Architecture:</strong> Leveraging Amazon Web and Google Cloud for robust and scalable cloud infrastructure. 
    Key components include Amazon SQS for message queuing, S3 for storage, EC2 for scalable computing capacity, 
    RDS for managed relational databases, ensuring high availability and reliability. In addition to Google's BigQuery for data analytics, Grafana (loki & prometheus) for monitoring and Kubernetes Engine for container orchestration</li>
    <li><strong>Front-End Excellence with Angular, React, Redux and Next.js:</strong> Building responsive and dynamic user 
    interfaces using React, coupled with Next.js for server-side rendering, enhancing SEO and 
    performance while integrating Redux for state management. Alternatively, Web-apps can be built with angular according to project requirements. Emphasis on creating interactive, user-centric web experiences that are both visually appealing and highly functional.</li>
    <li><strong>Versatile Back-End Development:</strong> Utilizing Node.js with Express for scalable and 
    efficient server-side applications. Alternatives like Elixir offer high concurrency and 
    fault tolerance, suitable for high-traffic systems. We ensure back-end solutions are robust, 
    secure, and seamlessly integrated with front-end architectures.</li>
    <li><strong>Database Mastery:</strong> Implementing a range of databases like MongoDB for NoSQL 
    solutions, PostgreSQL for complex relational database needs, and DynamoDB for 
    seamless AWS integration. Our approach ensures data integrity, speed, and scalability.</li>
    <li><strong>Blockchain-Web Integration:</strong> Creating decentralized applications (DApps) for smart 
    contracts and governance, integrating blockchain technologies with traditional web platforms. 
    Leveraging Ethereum and other blockchain networks for secure and transparent operations.</li>
    <li><strong>Load Balancing:</strong> Employing load balancing strategies for optimal performance and availability with stress testing of applications.</li>
    <li><strong>Cybersecurity:</strong> Advanced cybersecurity measures, including hardware protection for private keys, 
    are integrated to safeguard against digital threats, ensuring the security and integrity of web applications.</li>
    <h2>Comprehensive Development Process</h2>
    <li><strong>Tailored Requirement Analysis:</strong> In-depth understanding of client-specific needs and project goals.</li>
    <li><strong>Cutting-Edge Solution Design:</strong> Utilizing the latest web technologies and AWS components to design innovative solutions.</li>
    <li><strong>Seamless Development and Integration:</strong> Meticulous development process with a focus on integrating 
    blockchain technologies into web applications for enhanced functionality.</li>
    <li><strong>Rigorous Testing and Deployment:</strong> Conducting extensive tests to ensure reliability 
    and deploying robust solutions for real-world application.</li>
    <li><strong>Ongoing Support and Maintenance:</strong> Providing continuous support and updates, ensuring 
    the web applications remain at the forefront of technology and security.</li>
    <h2>Shaping the Future with Web Technology</h2>
    <p>Our web development services are not merely about building applications; they are 
    about pioneering new digital solutions that reshape how businesses operate. 
    We empower startups to global corporations with our technical expertise, 
    transforming visions into impactful digital realities. Step into a future 
    where web technology and blockchain innovation converge, creating new possibilities in the digital landscape.</p>
        `,
    images: [
      {
        url: ScWeb1,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: ScWeb2,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: ScWeb3,
        alt: 'pexels-photo-1840806.jpeg'
      }
    ],
    nextItem: {
      title: 'AI DEVELOPMENT',
      id: 3
    },
    prevItem: {
      title: 'BLOCKCHAIN DEVELOPMENT',
      id: 1
    }
  },
  {
    id: 3,
    title: 'AI DEVELOPMENT',
    description: 'description',
    imgUrl: marketingBg,
    properties: [
      {
        name: 'Architecture',
        image: servicePropImg
      },
      {
        name: 'Development',
        image: servicePropImg
      },
      {
        name: 'Testing',
        image: servicePropImg
      },
      {
        name: 'Deployment',
        image: servicePropImg
      }
    ],
    content: `<p>Dive into the realm of artificial intelligence with our specialized 
    AI development services. At the forefront of AI innovation, we focus on integrating 
    and refining advanced machine learning models to create intelligent, 
    responsive systems tailored to a wide array of business needs.</p>

    <h2>Cutting-Edge AI and Machine Learning Technologies</h2>
    <li><strong>Model Integration and Training:</strong> Leveraging pre-existing machine 
    learning models and frameworks like TensorFlow, PyTorch, and Keras. Our expertise 
    lies in fine-tuning these models to specific use cases, optimizing their performance through targeted training and validation.</li>
    
    <li><strong>Prompt Engineering and NLP:</strong> Specializing in Natural Language 
    Processing (NLP) technologies, we utilize GPT models and BERT for sophisticated 
    text analysis and generation. Expertise in prompt engineering ensures 
    tailored interactions and responses, making AI systems more intuitive and user-friendly.</li>
    
    <li><strong>Reinforcement Learning and Reward Systems:</strong> Implementing advanced 
    reinforcement learning algorithms, where AI systems learn optimal behaviors 
    through trial and error. Utilizing reward systems, these models adapt and evolve, making them ideal for complex decision-making scenarios.</li>
    
    <li><strong>Integration of RAG Models:</strong> Utilizing Retrieval-Augmented Generation 
    (RAG) models to enhance AI’s ability to access and use a vast knowledge base, 
    improving the quality and accuracy of generated content.</li>
    
    <li><strong>AI-Powered Analytics and Decision Making:</strong> Incorporating AI into 
    analytics for deeper insights and predictive capabilities. Using models like Random 
    Forest and XGBoost for advanced data analysis, helping businesses make informed, data-driven decisions.</li>
    
    <li><strong>Computer Vision and Image Processing:</strong> Utilizing convolutional neural 
    networks (CNNs) for image recognition and processing, applicable in areas like facial 
    recognition, object detection, and automated quality control.</li>
    
    <h2>Process for AI Solution Development</h2>
    <li><strong>Needs Assessment and Feasibility Study:</strong> Conducting thorough analysis of client requirements and the feasibility of AI integration.</li>
    <li><strong>Custom AI Solution Design:</strong> Crafting AI solutions using state-of-the-art technologies, tailored to client specifications.</li>
    <li><strong>Efficient Development and Integration:</strong> Meticulous development and seamless integration of AI capabilities into existing systems.</li>
    <li><strong>Rigorous Testing and Model Refinement:</strong> Extensive testing and continuous refinement of AI models to ensure peak performance.</li>
    <li><strong>Ongoing Support and Optimization:</strong> Providing continuous support and regular updates to AI systems, ensuring they remain effective and cutting-edge.</li>
    <h2>Pioneering the AI Revolution</h2>
    <p>Our AI development services are more than just about integrating technology; they are about creating intelligent systems that enhance and streamline business processes. Catering to a diverse range of industries, we empower organizations from startups to global corporations with AI solutions that drive innovation and efficiency. Step into a future where AI is not just a tool, but a game changer in the landscape of digital solutions.</p>
        `,
    images: [
      {
        url: ScMarket1,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: ScMarket2,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: ScMarket3,
        alt: 'pexels-photo-1840806.jpeg'
      }
    ],
    nextItem: {
      title: 'BLOCKCHAIN DEVELOPMENT',
      id: 1
    },
    prevItem: {
      title: 'WEB DEVELOPMENT',
      id: 2
    }
  }
];

export const getSimplifiedServices = () => {
  return services.map((item) => {
    return {
      id: item.id,
      title: item.title,
      imgUrl: item.imgUrl
    };
  });
};

export const getServiceById = (id) => {
  return services.find((item) => item.id === parseInt(id));
};
