import React from 'react';
import logoColored from '../assets/logo/logo_colored.svg';
import { useNavigate } from 'react-router-dom';

const FixedIcon = () => {
  const navigate = useNavigate();
  return (
    <img
      onClick={() => navigate('/')}
      src={logoColored}
      alt="3Cubes Logo"
      style={{
        cursor: 'pointer'
      }}
      className="logo-colored logo-colored-appears logo-ignore-position"
    />
  );
};

export default FixedIcon;
