import Blog1 from '../assets/images/Blog1.jfif';
import Blog2 from '../assets/images/Blog2.jfif';
import Blog3 from '../assets/images/Blog3.jfif';
import Blog4 from '../assets/images/Blog4.jfif';
import BlogContent1 from '../assets/images/BlogContent1.jpeg';
import BlogContent4 from '../assets/images/BlogContent4.webp';

export const blogs = [
  /* IMPORTANT!!! Don't change any key, only values */
  // To change, remove or add a new key you need to change the Blog.jsx and BlogContent.jsx files
  {
    id: 1,
    title: 'Vivamus libero mi, porttitor id diam, mattis semper est.',
    description: 'description',
    imgUrl: Blog1,
    category: 'marketing',
    datePosted: '21 July 2023',
    readingTime: '7 mins',
    badges: ['design', 'development', 'marketing', 'cryptocurrency'],
    /** This always should be a html string */
    content: `<p>Duis eget nibh lobortis, cursus arcu eu, aliquet tortor. Fusce consequat lacus odio,
    id ullamcorper tellus ornare vitae. <b>Aliquam</b> in justo imperdiet, accumsan felis eget,
    dignissim lectus.usce consequat lacus odio, id ullamcorper <a href="/example">tellus ornare vitae.</a></p>
    <p>
    Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus. Aliquam in
    highlight text quam quis dolor rutrum pharetra. Nunc vel elit a sem elementum link
    text nec a turpis. </p>
      <ul>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
      </ul>
      <p>
      Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar
      libero in eros ultrices, vel hendrerit mi aliquam. Cras ac risus tempus, eleifend
      nulla quis, bibendum sem. Donec at cursus velit. Vivamus et leo sit amet ante interdum
      malesuada quis sit amet justo.Nam condimentum ligula sed ipsum congue, eget porta sem
      posuere. Nullam pulvinar libero in eros ultrices, vel hendrerit mi aliquam.</p>
      `,
    images: [
      {
        url: BlogContent1,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: BlogContent4,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: BlogContent1,
        alt: 'pexels-photo-1840806.jpeg'
      }
    ]
  },
  {
    id: 2,
    title: 'Vivamus libero mi, porttitor id diam, mattis semper est.',
    description: 'description',
    imgUrl: Blog2,
    category: 'web development',
    datePosted: '21 July 2023',
    readingTime: '7 mins',
    badges: ['design', 'development', 'marketing', 'cryptocurrency'],
    content: `<p>Duis eget nibh lobortis, cursus arcu eu, aliquet tortor. Fusce consequat lacus odio,
    id ullamcorper tellus ornare vitae. <b>Aliquam</b> in justo imperdiet, accumsan felis eget,
    dignissim lectus.usce consequat lacus odio, id ullamcorper <a href="/example">tellus ornare vitae.</a></p>
    <p>
    Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus. Aliquam in
    highlight text quam quis dolor rutrum pharetra. Nunc vel elit a sem elementum link
    text nec a turpis. </p>
      <ul>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
      </ul>
      <p>
      Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar
      libero in eros ultrices, vel hendrerit mi aliquam. Cras ac risus tempus, eleifend
      nulla quis, bibendum sem. Donec at cursus velit. Vivamus et leo sit amet ante interdum
      malesuada quis sit amet justo.Nam condimentum ligula sed ipsum congue, eget porta sem
      posuere. Nullam pulvinar libero in eros ultrices, vel hendrerit mi aliquam.</p>
      `,
    images: [
      {
        url: BlogContent1,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: BlogContent4,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: BlogContent1,
        alt: 'pexels-photo-1840806.jpeg'
      }
    ]
  },
  {
    id: 3,
    title: 'Vivamus libero mi, porttitor id diam, mattis semper est.',
    description: 'description',
    imgUrl: Blog3,
    category: 'cryptocurrency',
    datePosted: '21 July 2023',
    readingTime: '7 mins',
    badges: ['design', 'development', 'marketing', 'cryptocurrency'],
    content: `<p>Duis eget nibh lobortis, cursus arcu eu, aliquet tortor. Fusce consequat lacus odio,
    id ullamcorper tellus ornare vitae. <b>Aliquam</b> in justo imperdiet, accumsan felis eget,
    dignissim lectus.usce consequat lacus odio, id ullamcorper <a href="/example">tellus ornare vitae.</a></p>
    <p>
    Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus. Aliquam in
    highlight text quam quis dolor rutrum pharetra. Nunc vel elit a sem elementum link
    text nec a turpis. </p>
      <ul>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
      </ul>
      <p>
      Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar
      libero in eros ultrices, vel hendrerit mi aliquam. Cras ac risus tempus, eleifend
      nulla quis, bibendum sem. Donec at cursus velit. Vivamus et leo sit amet ante interdum
      malesuada quis sit amet justo.Nam condimentum ligula sed ipsum congue, eget porta sem
      posuere. Nullam pulvinar libero in eros ultrices, vel hendrerit mi aliquam.</p>
      `,
    images: [
      {
        url: BlogContent1,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: BlogContent4,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: BlogContent1,
        alt: 'pexels-photo-1840806.jpeg'
      }
    ]
  },
  {
    id: 4,
    title: 'Vivamus libero mi, porttitor id diam, mattis semper est.',
    description: 'description',
    imgUrl: Blog4,
    category: 'motivation',
    datePosted: '21 July 2023',
    readingTime: '7 mins',
    badges: ['design', 'development', 'marketing', 'cryptocurrency'],
    content: `<p>Duis eget nibh lobortis, cursus arcu eu, aliquet tortor. Fusce consequat lacus odio,
    id ullamcorper tellus ornare vitae. <b>Aliquam</b> in justo imperdiet, accumsan felis eget,
    dignissim lectus.usce consequat lacus odio, id ullamcorper <a href="/example">tellus ornare vitae.</a></p>
    <p>
    Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus. Aliquam in
    highlight text quam quis dolor rutrum pharetra. Nunc vel elit a sem elementum link
    text nec a turpis. </p>
      <ul>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
        <li>Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus.</li>
      </ul>
      <p>
      Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar
      libero in eros ultrices, vel hendrerit mi aliquam. Cras ac risus tempus, eleifend
      nulla quis, bibendum sem. Donec at cursus velit. Vivamus et leo sit amet ante interdum
      malesuada quis sit amet justo.Nam condimentum ligula sed ipsum congue, eget porta sem
      posuere. Nullam pulvinar libero in eros ultrices, vel hendrerit mi aliquam.</p>
      `,
    images: [
      {
        url: BlogContent1,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: BlogContent4,
        alt: 'pexels-photo-1840806.jpeg'
      },
      {
        url: BlogContent1,
        alt: 'pexels-photo-1840806.jpeg'
      }
    ]
  }
];

export const getSimplifiedBlogs = () => {
  return blogs.map((blog) => ({
    id: blog.id,
    title: blog.title,
    description: blog.description,
    imgUrl: blog.imgUrl,
    category: blog.category,
    datePosted: blog.datePosted,
    readingTime: blog.readingTime,
    badges: blog.badges
  }));
};

export const getBlogById = (id) => {
  console.log('id: ', id);
  return blogs.find((blog) => blog.id === parseInt(id));
};

export const getFilteredBlogs = (category) => {
  return blogs.filter((blog) => blog.category.toLowerCase() === category.toLowerCase());
};
