import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logoBlack from '../assets/logo/logo_black.svg';
import logoColored from '../assets/logo/logo_colored.svg';
import logoWhite from '../assets/logo/logo_white.svg';
import testData from '../testData/data.json';
import lineOpen from '../assets/lotties/Line Open.json';
import AppFooter from '../components/CustomFooter';
import MenuModal from '../components/MenuModal';
import HomeFifthSection from '../components/HomeFifthSection';
import HomeFourthSection from '../components/HomeFourthSection';
import HomeSecondSection from '../components/HomeSecondSection';
import HomeSixthSection from '../components/HomeSixthSection';
import HomeThirdSection from '../components/HomeThirdSection';
import LogoBanner from '../components/LogoBanner';
import HomeLastSectionsMobile from '../components/HomeLastSectionsMobile';
import BackgroundFallingLines from '../components/BackgroundFallingLines';
import { motion, useScroll, useTransform } from 'framer-motion';

import '../styles/index.css';

function App() {
  const viewportHeight = window.innerHeight;
  const { scrollY } = useScroll();

  const centeredLineOpacity = useTransform(
    scrollY,
    [0, viewportHeight, viewportHeight * 2],
    [1, 1, 0]
  );

  const backgroundFallingLinesOpacity = useTransform(
    scrollY,
    [viewportHeight * 2.5, viewportHeight * 3],
    [0, 1]
  );

  // states
  const [isMobile, setIsMobile] = useState(false);

  // constant
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);

  // for redux
  useEffect(() => {
    if (data.length === 0) {
      testData.map(({ id, title, description }) =>
        dispatch({
          type: 'LOAD',
          payload: {
            id,
            title,
            description
          }
        })
      );
    }
  }, [dispatch, data]);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  // get width when window is resize
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (width <= 820 || height > width) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  const logoColoredOpacity = useTransform(
    scrollY,
    [viewportHeight, viewportHeight * 1.2, viewportHeight * 1.8, viewportHeight * 2],
    [0, 1, 1, 0]
  );

  const logoWhiteOpacity = useTransform(
    scrollY,
    [viewportHeight * 2, viewportHeight * 2.2, viewportHeight * 5.5, viewportHeight * 5.8],
    [0, 1, 1, 0]
  );

  const logoWhiteOpacityMobile = useTransform(
    scrollY,
    [viewportHeight * 2, viewportHeight * 2.2, viewportHeight * 4.5, viewportHeight * 5.5],
    [0, 1, 1, 0]
  );

  const logoBlackOpacity = useTransform(
    scrollY,
    [viewportHeight * 5.3, viewportHeight * 5.8, viewportHeight * 7],
    [0, 1, 1]
  );

  const logoBlackOpacityMobile = useTransform(
    scrollY,
    [viewportHeight * 4, viewportHeight * 5],
    [0, 0]
  );

  return (
    <>
      <motion.div
        style={{
          opacity: backgroundFallingLinesOpacity,
          position: 'fixed',
          top: 0,
          width: '100%',
          height: '100%'
        }}>
        <BackgroundFallingLines />
      </motion.div>

      <main id="mainContent">
        <motion.img
          src={logoColored}
          className={'logo-colored'}
          style={{
            opacity: logoColoredOpacity
          }}
        />
        <motion.img
          src={logoWhite}
          className={'logo-white'}
          style={{
            opacity: isMobile ? logoWhiteOpacityMobile : logoWhiteOpacity
          }}
        />
        <motion.img
          src={logoBlack}
          className={'logo-black'}
          style={{
            opacity: isMobile ? logoBlackOpacityMobile : logoBlackOpacity
          }}
        />

        <motion.div
          className={'line-open-container'}
          style={{
            opacity: centeredLineOpacity
          }}>
          <Lottie animationData={lineOpen} loop={true} />
        </motion.div>

        <MenuModal />

        <LogoBanner isMobile={isMobile} />
        <HomeSecondSection />
        <HomeThirdSection isMobile={isMobile} />
        <HomeFourthSection isMobile={isMobile} />
        {isMobile ? (
          <HomeLastSectionsMobile isMobile={isMobile} />
        ) : (
          <>
            <HomeFifthSection isMobile={isMobile} />
            <HomeSixthSection isMobile={isMobile} />
            <AppFooter />
          </>
        )}
      </main>
    </>
  );
}

export default App;
