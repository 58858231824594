import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import servicesVideo from '../assets/services/Services.mp4';
import servicesBlockchain from '../assets/services/blockchain.mp4';
import servicesMarketing from '../assets/services/marketing.mp4';
import servicesWeb from '../assets/services/web.mp4';
import { movingLinesGlobalText } from '../constants/appConstants';
import { motion, useScroll, useTransform, useMotionValueEvent } from 'framer-motion';

const serviceVideos = [servicesBlockchain, servicesWeb, servicesMarketing];
const serviceSnippets = [
  'Blockchain technology is revolutionizing industries with decentralized finance (DeFi) solutions and non-fungible token (NFT) platforms. This innovative approach includes integrating blockchain into existing systems, utilizing protocols like Proof of Stake, and creating unique NFT marketplaces for digital assets, ensuring secure, transparent, and efficient transactions.',
  "We excel in creating advanced web applications, integrating the strengths of AWS and Google Cloud. This includes using AWS's RDS for managed databases and CloudFront for content delivery, alongside Google Cloud's Firestore for real-time database solutions and App Engine for scalable app hosting. Our front-end toolkit features Vue.js and Next.js, paired with robust back-end technologies like Ruby on Rails and Elixir. Secure blockchain implementations with smart contracts are enabled using Solidity and Truffle, ensuring seamless web2 and web3 integration.",
  'We harness the power of AI to transform data into insights, utilizing models such as Random Forest and XGBoost for predictive analytics. Our expertise extends to computer vision, employing convolutional neural networks for tasks like image recognition and automated quality control. Custom AI solution design, rigorous testing, and model refinement are integral to our process, ensuring seamless integration and optimal performance. Continuous support and optimization keep our AI solutions at the forefront of innovation.'
];

const servicesNames = [
  { name: 'Blockchain Development', url: servicesBlockchain },
  { name: 'Web Development', url: servicesWeb },
  { name: 'AI Development', url: servicesMarketing }
];

const Container = ({ children, isMobile }) => {
  const viewportHeight = window.innerHeight;
  const { scrollY } = useScroll();

  const containerTransform = useTransform(
    scrollY,
    [
      viewportHeight * 3,
      viewportHeight * 3.5,
      viewportHeight * 4,
      viewportHeight * 5,
      viewportHeight * 5.5
    ],
    [viewportHeight, 0, -viewportHeight, -viewportHeight, -viewportHeight * 2]
  );
  const containerOpacity = useTransform(
    scrollY,
    [viewportHeight * 3, viewportHeight * 3.5, viewportHeight * 5, viewportHeight * 5.5],
    [0, 1, 1, 0]
  );

  if (isMobile) {
    return <section className={`sectionFour`}>{children}</section>;
  }
  return (
    <motion.section
      className={`sectionFour`}
      style={{
        opacity: containerOpacity,
        top: containerTransform
      }}>
      {children}
    </motion.section>
  );
};

const HomeFourthSection = ({ isMobile }) => {
  const navigate = useNavigate();
  const serviceVideoRef = useRef(null);

  const [activeService, setActiveService] = useState(0);
  const [serviceVideoUrl, setServiceVideoUrl] = useState(serviceVideos[0]);
  // ref load video on change source
  useEffect(() => {
    serviceVideoRef.current?.load();
  }, [activeService, serviceVideoUrl]);

  const [lineProgress, setLineProgress] = useState(0);

  useEffect(() => {
    if (activeService === 0) {
      setLineProgress(20);
    } else if (activeService === 1) {
      setLineProgress(55);
    } else {
      setLineProgress(100);
    }
  }, [activeService]);

  const viewportHeight = window.innerHeight;
  const { scrollY } = useScroll();

  const scrollingTransform = useTransform(
    scrollY,
    [viewportHeight * 4, viewportHeight * 5],
    [0, 90]
  );

  useMotionValueEvent(scrollingTransform, 'change', (latest) => {
    if (latest < 30) {
      setActiveService(0);
      setServiceVideoUrl(serviceVideos[0]);
    } else if (latest >= 30 && latest < 60) {
      setActiveService(1);
      setServiceVideoUrl(serviceVideos[1]);
    } else if (latest >= 60) {
      setActiveService(2);
      setServiceVideoUrl(serviceVideos[2]);
    }
  });

  return (
    <Container isMobile={isMobile}>
      <div className="pre-sectionFour">
        <div>
          <h1 className={'section-five-marqueeReverse'}>
            <span className={'section-five-movingLines'}>{movingLinesGlobalText}</span>
          </h1>
        </div>
        <div>
          <h1 className={'section-five-marquee'}>
            <span className={'section-five-movingLines'}>{movingLinesGlobalText}</span>
          </h1>
        </div>
        <div>
          <h1 className={'section-five-marqueeReverse'}>
            <span className={'section-five-movingLines'}>{movingLinesGlobalText}</span>
          </h1>
        </div>
        <div>
          <h1 className={'section-five-marquee'}>
            <span className={'section-five-movingLines'}>{movingLinesGlobalText}</span>
          </h1>
        </div>
        <div>
          <h1 className={'section-five-marqueeReverse'}>
            <span className={'section-five-movingLines'}>{movingLinesGlobalText}</span>
          </h1>
        </div>
      </div>

      <div className="section-four-container __flex_col">
        <h1 className="section-four-title">
          WE OFFER AN{' '}
          <span style={{ fontWeight: '400' }}>
            END-TO-END<br></br> SOLUTION
          </span>{' '}
          WITH OUR SERVICES.
        </h1>
        <div className="section-four-description">
          <div id="borderLeft"></div>
          <div id="borderBottom"></div>
          <h1 id="boxContent">
            <strong>{servicesNames[activeService].name}:</strong> {serviceSnippets[activeService]}
          </h1>
          <div className={'section-five-servicesButton'}>
            <Button
              variant="text"
              endIcon={<ArrowForwardIcon />}
              style={{ color: 'white', marginTop: '1rem' }}
              onClick={() => navigate('/services')}>
              Read More
            </Button>
          </div>
        </div>
        <div className="hex-container">
          <div className="hex-container-line">
            <div
              style={{
                width: `${lineProgress}%`
              }}
            />
          </div>
          {servicesNames.map((service, i) => (
            <div
              className={`hex-item ${activeService >= i ? 'hex-item-active' : ''} ${
                activeService === i ? 'hex-item-text-active' : ''
              }`}
              key={`hex-container-${i}`}
              onClick={() => {
                setActiveService(i);
                setServiceVideoUrl(service.url);
              }}>
              <h1 className="hex-item-text">{service.name}</h1>
              <div className={`hex-item-icon`}></div>
            </div>
          ))}
        </div>
        <div className="section-four-video">
          <video ref={serviceVideoRef} autoPlay muted>
            <source src={serviceVideoUrl ?? servicesVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </Container>
  );
};

export default HomeFourthSection;
