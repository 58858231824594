import React from 'react';
import { Grid, Box, Button, IconButton, Drawer } from '@mui/material';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { OfficeLocations } from '../constants/officeLocations';
import { FooterLinks, FooterLinksSecondary } from '../constants/footerLink';
import globeMapSvg from '../assets/globe/globe-map.svg';
import { useEffect } from 'react';
import { useState } from 'react';
import ContactModal from './ContactModal';
import FixedIcon from './FixedIcon';
import { motion, useScroll, useTransform } from 'framer-motion';

const AppFooter = ({ ignorePosition }) => {
  const viewportHeight = window.innerHeight;
  const { scrollY } = useScroll();

  const containerTransform = useTransform(
    scrollY,
    [viewportHeight * 7, viewportHeight * 7.5],
    [viewportHeight, 0]
  );

  const containerOpacity = useTransform(
    scrollY,
    [viewportHeight * 7, viewportHeight * 7.5],
    [0, 1]
  );

  const [openModal, setOpenModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(1200);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (width < 1200) {
      setIsMobile(true);
    }
    if (width >= 1200) {
      setIsMobile(false);
    }
  });

  const renderFooterBar = () => {
    return (
      <div className="footer-appbar">
        <h3 className="footer-appbar-rights">© 2023 3CUBES, INC. ALL RIGHTS RESERVED.</h3>

        <div className="footer-appbar-links">
          <div className="footer-appbar-first-links">
            {FooterLinks.map((item, i) => (
              <>
                <a key={i} href={item.url}>
                  {item.name}
                </a>
              </>
            ))}
          </div>

          <div className={'divider'} />

          <div className="footer-appbar-second-links">
            {FooterLinksSecondary.map((item, i) => (
              <>
                <a key={i} href={item.url}>
                  {item.name}
                </a>
              </>
            ))}
            <div />
          </div>
        </div>
        <div className="footer-appbar-social">
          <a href="https://www.facebook.com/">
            <IconButton>
              <FacebookRoundedIcon className={'footer-social-icon'} />
            </IconButton>
          </a>
          <a href="https://www.instagram.com/">
            <IconButton>
              <InstagramIcon className={'footer-social-icon'} />
            </IconButton>
          </a>
          <a href="https://www.twitter.com/">
            <IconButton>
              <TwitterIcon className={'footer-social-icon'} />
            </IconButton>
          </a>
        </div>
      </div>
    );
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.div
      style={
        ignorePosition
          ? {}
          : {
              top: containerTransform,
              opacity: containerOpacity
            }
      }
      className={`footer ${ignorePosition ? 'footer-non-absolute' : ''}`}>
      <div className="__flex_col footer-container">
        <div className="footer-subcontainer-1">
          <div className="footer-title-container">
            <h1 className={'footer-title'}>
              LET’S GET IN <br />
              <b>TOUCH</b>
            </h1>
            <div>
              <button
                className="footer-button"
                onClick={() => {
                  setOpenModal(true);
                }}>
                CONTACT US
              </button>
            </div>
          </div>
          <div className="__flex_col footer-image-and-offices">
            <div className="__flex_row  footer-offices-container">
              {OfficeLocations.map((office, i) => (
                <div key={i} className="footer-office">
                  <h1 className="footer-office-title">{office.name}</h1>
                  <p>{office.address}</p>
                  <p>
                    <b>Contact:</b> {office.contact}
                  </p>
                </div>
              ))}
            </div>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="footer-image-container">
                <img alt={'bg-map.jpg'} src={globeMapSvg} width="100%" className="footer-image" />

                <div className="footer-hex-container pos1">
                  <div className="footer-hex" />
                </div>
                <div className="footer-hex-container pos2">
                  <div className="footer-hex" />
                </div>
                <div className="footer-hex-container pos3">
                  <div className="footer-hex" />
                </div>
              </div>
            </Grid>
            <div className={'lets-meet-container'}>
              <h3 className="lets-meet-title">CAREER IN 3CUBES</h3>
              <p className="lets-meet-text">Do you want to collaborate?</p>
              <div className="lets-meet-line-decoration" />
              <button
                onClick={() => {
                  setOpenModal(true);
                }}
                className="lets-meet-button">
                LET’S MEET
              </button>
            </div>
            <div className="scroll-top-button" style={isMobile ? { display: 'none' } : {}}>
              <Button
                variant="text"
                className={'footer-scroll'}
                endIcon={<ArrowForwardIcon />}
                onClick={handleScrollTop}>
                <h1>Scroll Top</h1>
              </Button>
            </div>
          </div>
        </div>
        {renderFooterBar()}
      </div>

      <div className={'footer-box'}>
        <Drawer
          anchor={'top'}
          open={openModal}
          onClose={() => setOpenModal(false)}
          style={{
            height: '100vh'
          }}>
          <Box
            sx={{ width: 'auto' }}
            role="presentation"
            style={{
              backgroundColor: '#030e62',
              paddingBottom: '3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh'
            }}>
            <div style={{ width: '100%' }}>
              <FixedIcon />
              <div>
                <ContactModal onClose={() => setOpenModal(false)} />
              </div>
            </div>
          </Box>
        </Drawer>
      </div>
    </motion.div>
  );
};

export default AppFooter;
