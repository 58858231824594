import { Chip, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import { AppButton } from '../components/button';

const Gallery = ({
  title,
  description,
  filters,
  data,
  activeFilter,
  setActiveFilter,
  onLoadInfinite
}) => {
  return (
    <>
      {title != null ? <h1 className="gallery-title">{title}</h1> : null}
      {description != null ? <p className="gallery-description">{description}</p> : null}

      {filters != null ? (
        <div className="gallery-categories">
          {filters.map((filter) => (
            <>
              <Chip
                onClick={() => setActiveFilter(filter.id)}
                label={filter.name}
                name={filter.id}
                variant={filter.id === activeFilter ? 'contained' : 'outlined'}
                sx={{ m: '5px 5px' }}
              />
            </>
          ))}
        </div>
      ) : null}
      {data != null ? (
        <ul className="gallery-gallery">
          {data.map((item) => {
            return (
              <li
                key={`gallery-item-${item.id}`}
                className="gallery-item"
                onClick={() => (item.onClick != null ? item.onClick() : null)}>
                {item.titlePosition === 'top' ? (
                  <div className="gallery-item-description">
                    {item.title != null ? (
                      <h3 className="gallery-item-description-title">{item.title}</h3>
                    ) : null}
                    {item.description != null ? (
                      <div className="gallery-item-description-data">{item.description}</div>
                    ) : null}
                  </div>
                ) : null}
                <div className="gallery-item-image">
                  {item.badge != null ? (
                    <div className="gallery-item-category">
                      <Chip label={item.badge} name={item.badge} className="gallery-chip" />
                    </div>
                  ) : null}
                  <img alt={item.title} loading="lazy" src={item.imgUrl} />
                </div>
                {item.titlePosition === 'bottom' ? (
                  <div className="gallery-item-description">
                    {item.title != null ? (
                      <h3 className="gallery-item-description-title">{item.title}</h3>
                    ) : null}
                    {item.description != null ? (
                      <div className="gallery-item-description-data">{item.description}</div>
                    ) : null}
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
      ) : null}
      {onLoadInfinite != null ? (
        <Grid container justifyContent="center" sx={{ mt: 5 }}>
          <Grid item xs={12} s={12} md={6} l={2} xl={2}>
            <AppButton buttonText="BUTTON XLARGE" />
            <Typography sx={{ mt: 5, textAlign: 'center' }}>Showing 24 of 58 Products</Typography>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default Gallery;
