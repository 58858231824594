import React from 'react';
import MenuModal from '../components/MenuModal';

const PrivacyPolicy = () => {
  return (
    <>
      <MenuModal />
      <div>Privacy</div>
    </>
  );
};

export default PrivacyPolicy;
