import { Chip } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

const RelatedContent = ({ data, extendedImage, title }) => {
  const [isMobile, setIsMobile] = useState(false);

  // get width when window is resize
  useEffect(() => {
    // get window width
    const width = window.innerWidth;
    if (width < 1200) {
      setIsMobile(true);
    }
    if (width >= 1200) {
      setIsMobile(false);
    }
  });

  return (
    <div className="related-content">
      <h3 className="related-content-title">{title}</h3>
      <ul className="related-gallery">
        {isMobile ? (
          <Carousel className="related-content-carrousel">
            {data.map((item) => (
              <li
                key={`gallery-item-${item.id}`}
                className="gallery-item"
                onClick={() => (item.onClick != null ? item.onClick() : null)}>
                <div
                  className={`gallery-item-image ${
                    extendedImage ? 'gallery-item-image-extended' : ''
                  }`}>
                  {item.badge != null ? (
                    <div className="gallery-item-category">
                      <Chip label={item.badge} name={item.badge} className="gallery-chip" />
                    </div>
                  ) : null}
                  <img alt={item.title} loading="lazy" src={item.imgUrl} />
                </div>
                <div className="gallery-item-description">
                  {item.title != null ? (
                    <h3 className="gallery-item-description-title">{item.title}</h3>
                  ) : null}
                  {item.description != null ? (
                    <div className="gallery-item-description-data">{item.description}</div>
                  ) : null}
                </div>
              </li>
            ))}
          </Carousel>
        ) : (
          data.map((item) => (
            <li
              key={`gallery-item-${item.id}`}
              className="gallery-item  gallery-item-related"
              onClick={() => (item.onClick != null ? item.onClick() : null)}>
              <div
                className={`gallery-item-image ${
                  extendedImage ? 'gallery-item-image-extended' : ''
                }`}>
                {item.badge != null ? (
                  <div className="gallery-item-category">
                    <Chip label={item.badge} name={item.badge} className="gallery-chip" />
                  </div>
                ) : null}
                <img alt={item.title} loading="lazy" src={item.imgUrl} />
              </div>
              <div className="gallery-item-description">
                {item.title != null ? (
                  <h3 className="gallery-item-description-title">{item.title}</h3>
                ) : null}
                {item.description != null ? (
                  <div className="gallery-item-description-data">{item.description}</div>
                ) : null}
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default RelatedContent;
