import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react';
import { projectsCarouselLeft, projectsCarouselRight } from '../constants/appConstants';
import { useScroll, useTransform, motion } from 'framer-motion';

const HomeSixthSection = () => {
  const viewportHeight = window.innerHeight;
  const { scrollY } = useScroll();

  const containerTransform = useTransform(
    scrollY,
    [viewportHeight * 6, viewportHeight * 6.5, viewportHeight * 7, viewportHeight * 7.5],
    [viewportHeight, 0, 0, -viewportHeight]
  );

  const containerOpacity = useTransform(
    scrollY,
    [viewportHeight * 6, viewportHeight * 6.5, viewportHeight * 7.2, viewportHeight * 7.5],
    [0, 1, 1, 0]
  );

  return (
    <motion.section
      style={{
        top: containerTransform,
        opacity: containerOpacity
      }}
      className={`sectionSix`}>
      <div className="section-six-container">
        <div className="section-six-texts-container">
          <h2 className="section-six-title">
            DISCOVER OUR <br></br>
            <span>PROJECTS</span>
          </h2>

          <p className={'section-six-description'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus dapibus nibh orci,
            quis pulvinar arcu interdum a. Etiam lobortis, odio ac rutrum iaculis, enim nulla
            sollicitudin enim, venenatis scelerisque diam diam non arcu.
          </p>

          <a href="/projects" className="section-six-button">
            Discover All Projects <ArrowForwardIcon />
          </a>
        </div>
        <div className="section-six-carrousels-container">
          <div className="section-six-first-carrousel">
            {projectsCarouselLeft.map((project, i) => (
              <div key={`carrousel-left-${i}`} className={'section-six-imgScroll'}>
                <img src={project.img} className={'section-six-projectImg'} />
                <h1
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    fontWeight: '400',
                    color: '#030E62',
                    position: 'relative',
                    top: '-1rem'
                  }}>
                  {project.projectName}
                </h1>
              </div>
            ))}
          </div>

          <div className="section-six-second-carrousel">
            {projectsCarouselRight.map((project, i) => (
              <div key={`carrousel-right-${i}`} className={'section-six-imgScrollReverse'}>
                <img src={project.img} className={'section-six-projectImg'} />
                <h1
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    fontWeight: '400',
                    color: '#030E62',
                    position: 'relative',
                    top: '-1rem'
                  }}>
                  {project.projectName}
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default HomeSixthSection;
