export const FooterLinks = [
  { name: 'HOME', url: '/home' },
  { name: 'OUR PROJECT', url: '/projects' },
  { name: 'OUR SERVICES', url: '/services' },
  { name: 'BLOG', url: '/blog' },
  { name: 'CONTACT', url: '/contact-us' }
];

export const FooterLinksSecondary = [
  { name: 'PRIVACY', url: '/privacy' },
  { name: 'LEGAL/TERMS', url: '/legal' }
];
