import React from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useEffect, useState } from 'react';
import AppFooter from '../components/CustomFooter';
import MenuModal from '../components/MenuModal';
import { BlogCategories } from '../constants/blogCategories';
import BackgroundFallingLines from '../components/BackgroundFallingLines';
import { useNavigate } from 'react-router-dom';
import FixedIcon from '../components/FixedIcon';
import Gallery from '../components/Gallery';
import { getFilteredBlogs, getSimplifiedBlogs } from '../constants/blogs';

const Blog = () => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState('all');
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const getBlogs = getSimplifiedBlogs();

    setBlogs(getBlogs);
  }, []);

  useEffect(() => {
    if (!activeCategory || activeCategory.toLowerCase() === 'all') {
      const allBlogs = getSimplifiedBlogs();
      return setBlogs(allBlogs);
    }
    const filteredBlogs = getFilteredBlogs(activeCategory);
    return setBlogs(filteredBlogs);
  }, [activeCategory]);

  if (!blogs) return null;

  return (
    <div className="__format">
      <FixedIcon />
      <BackgroundFallingLines />
      <MenuModal />

      <div className="blog-container">
        <Gallery
          title={'3CUBES BLOG'}
          description={
            'Quisque facilisis mi eu euismod vehicula. Duis at elit lorem. Donec metus felis, posuere et semper ac, efficitur non quam. In tincidunt, ante sed auctor bibendum'
          }
          filters={BlogCategories.map((category) => ({
            id: category,
            name: category
          }))}
          onLoadInfinite={() => console.log('load more')}
          activeFilter={activeCategory}
          setActiveFilter={setActiveCategory}
          data={blogs.map((blog) => {
            const description = (
              <div className="gallery-item-description-data">
                <DateRangeIcon />
                <p>{blog.datePosted} </p>
                <p>{blog.readingTime}</p>
              </div>
            );
            return {
              id: blog.id,
              title: blog.title,
              titlePosition: 'bottom',
              description,
              imgUrl: blog.imgUrl,
              badge: blog.category,
              onClick: () => navigate(`/blog/${blog.id}`)
            };
          })}
        />
      </div>
      <AppFooter ignorePosition={true} />
    </div>
  );
};

export default Blog;
