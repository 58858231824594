import React, { useState, useEffect } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppFooter from '../components/CustomFooter';
import { useNavigate, useParams } from 'react-router-dom';
import MenuModal from '../components/MenuModal';
import BackgroundFallingLines from '../components/BackgroundFallingLines';
import FixedIcon from '../components/FixedIcon';
import parse from 'html-react-parser';
import Carousel from 'react-material-ui-carousel';
import { getServiceById } from '../constants/services';
import { getSimplifiedProjects } from '../constants/projects';
import RelatedContent from '../components/RelatedContent';

const ServicesContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  // get width when window is resize
  useEffect(() => {
    // get window width
    const width = window.innerWidth;
    if (width < 1200) {
      setIsMobile(true);
    }
    if (width >= 1200) {
      setIsMobile(false);
    }
  });

  const [item, setItem] = useState(null);
  const [relatedProjects, setRelatedProjects] = useState([]);

  useEffect(() => {
    if (params.serviceId == null) return navigate('/services');

    const getItem = getServiceById(params.serviceId);

    console.log('getitem');
    console.log(getItem);

    setItem(getItem);

    const relatedProjects = getSimplifiedProjects();

    setRelatedProjects(relatedProjects);

    return window.scrollTo(0, 0);
  }, [params.serviceId]);

  if (item == null) return null;

  return (
    <div className="__format">
      <FixedIcon />
      <BackgroundFallingLines />
      <MenuModal />

      <div className="blog-container">
        <h1 className="service-title">
          {item.title}
          <div className="service-title-added">service</div>
        </h1>

        <div className="service-image-container">
          <img alt={item.name} src={item.imgUrl} className="service-image" />
        </div>

        {item.properties ? (
          <div className="service-properties">
            {isMobile ? (
              <Carousel className="footer-offices-carrousel">
                {item.properties.map((property, i) => {
                  return (
                    <div className="service-property" key={`service-prop-${i}`}>
                      <img
                        alt={property.name}
                        src={property.image}
                        className="service-property-image"
                      />
                      <p className="service-property-name">{property.name}</p>
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              item.properties.map((property, i) => (
                <div className="service-property" key={`service-prop-${i}`}>
                  <img
                    alt={property.name}
                    src={property.image}
                    className="service-property-image"
                  />
                  <p className="service-property-name">{property.name}</p>
                </div>
              ))
            )}
          </div>
        ) : null}

        <p className="blog-content">{parse(item.content ?? '')}</p>

        <div className="service-navigation">
          <p className="service-navigation-title">other services</p>
          <div className="service-navigation-arrows">
            <div
              className="service-navigation-arrow-left"
              onClick={() => (window.location.href = `/services/${item.prevItem?.id}`)}>
              <ArrowBackIcon fontSize="large" />
              <p>{item.prevItem?.title}</p>
            </div>

            <div
              className="service-navigation-arrow-right"
              onClick={() => (window.location.href = `/services/${item.nextItem?.id}`)}>
              <p>{item.nextItem?.title}</p>
              <ArrowForwardIcon fontSize="large" />
            </div>
          </div>
        </div>

        <RelatedContent
          title="related projects"
          data={relatedProjects.map((project) => ({
            title: (
              <div className="related-projects-title">
                <p>{project.title}</p>
                <span className="related-projects-discover-text">
                  Discover
                  <ArrowForwardIcon fontSize="small" className="related-projects-discover-icon" />
                </span>
              </div>
            ),
            imgUrl: project.url,
            onClick: () => navigate(`/projects/${project.id}`)
          }))}
          extendedImage={true}
        />
      </div>

      <AppFooter ignorePosition={true} />
    </div>
  );
};

export default ServicesContent;
