import AzBlch4 from '../assets/images/AzBlockchain4.webp';
import AzWeb4 from '../assets/images/AzWeb4.webp';
import AzMarket4 from '../assets/images/AzMarket4.jpeg';
import servicePropImg from '../assets/services/service-prop.png';

export const projects = [
  {
    id: 1,
    title: 'BLOCKCHAIN',
    imgUrl: AzBlch4,
    category: 'web development',
    properties: [
      {
        name: 'design',
        image: servicePropImg
      },
      {
        name: 'development',
        image: servicePropImg
      },
      {
        name: 'marketing',
        image: servicePropImg
      },
      {
        name: 'cryptocurrency',
        image: servicePropImg
      }
    ],
    content: `<p>Duis eget nibh lobortis, cursus arcu eu, aliquet tortor. Fusce consequat lacus odio,
        id ullamcorper tellus ornare vitae. Aliquam in justo imperdiet, accumsan felis eget,
        dignissim lectus.usce consequat lacus odio, id ullamcorper tellus ornare vitae.
        Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus. Aliquam in
        highlight text quam quis dolor rutrum pharetra. Nunc vel elit a sem elementum link
        text nec a turpis. </p>
        <p>Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar </p>
        <ul>
          <li>libero in eros ultrices, vel hendrerit mi aliquam.</li>
          <li>Cras ac risus tempus, eleifend nulla quis, bibendum sem.</li>
          <li>Donec at cursus velit. Vivamus et leo sit amet ante interdum malesuada quis sit amet justo.</li>
        </ul>
        <p>
        Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar
        libero in eros ultrices, vel hendrerit mi aliquam. Cras ac risus tempus, eleifend
        nulla quis, bibendum sem. Donec at cursus velit. Vivamus et leo sit amet ante interdum
        malesuada quis sit amet justo.Nam condimentum ligula sed ipsum congue, eget porta sem
        posuere. Nullam pulvinar libero in eros ultrices, vel hendrerit mi aliquam.</p>
        `
  },
  {
    id: 2,
    title: 'WEB DEVELOPMENT',
    description: 'description',
    imgUrl: AzWeb4,
    category: 'blockchain development',
    properties: [
      {
        name: 'design',
        image: servicePropImg
      },
      {
        name: 'development',
        image: servicePropImg
      },
      {
        name: 'marketing',
        image: servicePropImg
      },
      {
        name: 'cryptocurrency',
        image: servicePropImg
      }
    ],
    content: `<p>Duis eget nibh lobortis, cursus arcu eu, aliquet tortor. Fusce consequat lacus odio,
        id ullamcorper tellus ornare vitae. Aliquam in justo imperdiet, accumsan felis eget,
        dignissim lectus.usce consequat lacus odio, id ullamcorper tellus ornare vitae.
        Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus. Aliquam in
        highlight text quam quis dolor rutrum pharetra. Nunc vel elit a sem elementum link
        text nec a turpis. </p>
        <p>Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar </p>
        <ul>
          <li>libero in eros ultrices, vel hendrerit mi aliquam.</li>
          <li>Cras ac risus tempus, eleifend nulla quis, bibendum sem.</li>
          <li>Donec at cursus velit. Vivamus et leo sit amet ante interdum malesuada quis sit amet justo.</li>
        </ul>
        <p>
        Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar
        libero in eros ultrices, vel hendrerit mi aliquam. Cras ac risus tempus, eleifend
        nulla quis, bibendum sem. Donec at cursus velit. Vivamus et leo sit amet ante interdum
        malesuada quis sit amet justo.Nam condimentum ligula sed ipsum congue, eget porta sem
        posuere. Nullam pulvinar libero in eros ultrices, vel hendrerit mi aliquam.</p>
        `
  },
  {
    id: 3,
    title: 'MARKETING DEVELOPMENT',
    description: 'description',
    imgUrl: AzMarket4,
    category: 'marketing development',
    properties: [
      {
        name: 'design',
        image: servicePropImg
      },
      {
        name: 'development',
        image: servicePropImg
      },
      {
        name: 'marketing',
        image: servicePropImg
      },
      {
        name: 'cryptocurrency',
        image: servicePropImg
      }
    ],
    content: `<p>Duis eget nibh lobortis, cursus arcu eu, aliquet tortor. Fusce consequat lacus odio,
        id ullamcorper tellus ornare vitae. Aliquam in justo imperdiet, accumsan felis eget,
        dignissim lectus.usce consequat lacus odio, id ullamcorper tellus ornare vitae.
        Aliquam in justo imperdiet, accumsan felis eget, dignissim lectus. Aliquam in
        highlight text quam quis dolor rutrum pharetra. Nunc vel elit a sem elementum link
        text nec a turpis. </p>
        <p>Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar </p>
        <ul>
          <li>libero in eros ultrices, vel hendrerit mi aliquam.</li>
          <li>Cras ac risus tempus, eleifend nulla quis, bibendum sem.</li>
          <li>Donec at cursus velit. Vivamus et leo sit amet ante interdum malesuada quis sit amet justo.</li>
        </ul>
        <p>
        Nam condimentum ligula sed ipsum congue, eget porta sem posuere. Nullam pulvinar
        libero in eros ultrices, vel hendrerit mi aliquam. Cras ac risus tempus, eleifend
        nulla quis, bibendum sem. Donec at cursus velit. Vivamus et leo sit amet ante interdum
        malesuada quis sit amet justo.Nam condimentum ligula sed ipsum congue, eget porta sem
        posuere. Nullam pulvinar libero in eros ultrices, vel hendrerit mi aliquam.</p>
        `
  }
];

export const getSimplifiedProjects = () => {
  return projects.map((project) => {
    return {
      id: project.id,
      title: project.title,
      category: project.category,
      url: project.imgUrl
    };
  });
};

export const getFilteredProjects = (category) => {
  return projects
    .filter((project) => project.category.toLowerCase() === category.toLowerCase())
    .map((project) => {
      return {
        id: project.id,
        title: project.title,
        category: project.category,
        url: project.imgUrl
      };
    });
};

export const getProjectById = (id) => {
  return projects.find((project) => project.id === parseInt(id));
};

export const getOtherProjects = (id) => {
  return projects
    .filter((project) => project.id !== parseInt(id))
    .map((project) => ({
      id: project.id,
      title: project.title,
      category: project.category,
      url: project.imgUrl
    }));
};
